import React from "react";
import { Route } from "react-router-dom";

import Items from "../pages/Items";
import Unit from "../pages/Unit";

import { Bom, BomAccess, BomManage } from "../pages/bom";
import {
  Packaging,
  PackagingAccess,
  PackagingManage,
} from "../pages/packaging";
import {
  PackingSet,
  PackingSetAccess,
  PackingSetManage,
} from "../pages/packing-set";

import {
  ShippingType,
  ShippingTypeAccess,
  ShippingTypeManage,
} from "../pages/shipping-type";

import {
  Customers,
  CustomersAccess,
  CustomersManage,
} from "../pages/customers";
import {
  Suppliers,
  SuppliersAccess,
  SuppliersManage,
} from "../pages/suppliers";

export const RDDataRouter = (
  <>
    <Route path="/items" element={<Items />} />
    <Route path="/unit" element={<Unit />} />

    <Route path="/bom/" exact element={<Bom />}>
      <Route index element={<BomAccess />} />
      <Route path="manage/:action" element={<BomManage />} />
    </Route>

    <Route path="/packaging/" exact element={<Packaging />}>
      <Route index element={<PackagingAccess />} />
      <Route path="manage/:action" element={<PackagingManage />} />
    </Route>

    <Route path="/packing-set/" exact element={<PackingSet />}>
      <Route index element={<PackingSetAccess />} />
      <Route path="manage/:action" element={<PackingSetManage />} />
    </Route>

    <Route path="/customers/" exact element={<Customers />}>
      <Route index element={<CustomersAccess />} />
      <Route path="manage/:action" element={<CustomersManage />} />
    </Route>

    <Route path="/suppliers/" exact element={<Suppliers />}>
      <Route index element={<SuppliersAccess />} />
      <Route path="manage/:action" element={<SuppliersManage />} />
    </Route>

    <Route path="/shipping-type/" exact element={<ShippingType />}>
      <Route index element={<ShippingTypeAccess />} />
      <Route path="manage/:action" element={<ShippingTypeManage />} />
    </Route>
  </>
);
