import React from "react";
import { Route } from "react-router-dom";

import {
  CoaPrintPreview,
  LoiPrintPreview,
  DlnPrintPreview,
  QuoPrintPreview,
  SptPrintPreview,
  EstPrintPreview,
} from "../components/print";

export const PrintRouter = (
  <>
    <Route path="/coa-print/:code/:print?" element={<CoaPrintPreview />} />
    <Route path="/loi-print/:code/:print?" element={<LoiPrintPreview />} />
    <Route path="/dln-print/:code/:print?" element={<DlnPrintPreview />} />
    <Route path="/quo-print/:code/:print?" element={<QuoPrintPreview />} />
    <Route path="/spt-print/:code/:print?" element={<SptPrintPreview />} />
    <Route path="/est-print/:code/:print?" element={<EstPrintPreview />} />
  </>
);
