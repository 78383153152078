/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, Form, Input, Button, Flex, message, Typography } from "antd";
import { Row, Col, Space, InputNumber, Divider, Badge, Select } from "antd";

import { SaveFilled, SearchOutlined } from "@ant-design/icons";

import { ButtonBack } from "../../../components/button";
import ModalPackingType from "../../../components/modal/ERP/packing/ModalPackingType";
import ModalSupplier from "../../../components/modal/supplier/ModalSupplier";
import ModalUnit from "../../../components/modal/unit/ModalUnit";

import { pkmaster } from "./model";
import PackingService from "../../../service/ERP/Packing.service";
import OptionService from "../../../service/ERP/Options.service";
import { useLocation, useNavigate } from "react-router";
import { delay } from "../../../utils/util";

const pkservice = PackingService();
const opService = OptionService();
const { Text } = Typography;

const from = "/ERP/packing-master";

const PackingManage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { config } = location.state || { config: null };
  const [form] = Form.useForm();

  const [optionUnit, setOptionUnit] = useState([]);
  const [optionPrefix, setOptionPrefix] = useState([]);

  const [formDetail, setFormDetail] = useState(pkmaster);

  const [openModalPackingType, setOpenModalPackingType] = useState(false);
  const [openModalSupplier, setOpenModalSupplier] = useState(false);
  const [openModalUnit, setOpenModalUnit] = useState(false);
  // const [packageTypeOption, setPackageTypeOption] = useState([]);
  const [prefixCode, setPrefixCode] = useState(null);

  const init = async () => {
    if (config?.action !== "create") {
      pkservice
        .get(config?.code)
        .then(async (res) => {
          const { data: detail } = res.data;
          const initialValues = { ...pkmaster, ...detail };

          form.setFieldValue("packing_code1", detail.packing_code);
          setFormDetail(initialValues);
          form.setFieldsValue(initialValues);
        })
        .catch((err) => {
          // console.warn(err);
          const { data } = err.response;
          message.error(data?.message || "error request");
        });
    } else {
      setFormDetail(pkmaster);
      form.setFieldsValue(pkmaster);
    }
  };

  useEffect(() => {
    init();
    GetItemsUnit();
    GetPrefix();
    return () => {};
  }, []);

  const GetItemsUnit = () => {
    opService.optionsUnit().then((res) => {
      let { data } = res.data;
      setOptionUnit(data);
    });
  };

  const GetPrefix = () => {
    opService.optionsRuncode({ p: "packing" }).then((res) => {
      let { data } = res.data;
      // let code = data.run_number
      setOptionPrefix(data);
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleChoosedPackingType = (v) => {
    // console.log(v)
    const f = form.getFieldsValue();
    const val = {
      ...formDetail,
      ...f,
      packingtype_id: v.packingtype_id,
      packingtype_name: v.packingtype_name,
    };

    setFormDetail(val);

    form.setFieldsValue(val);
  };

  const handleChoosedSupplier = (v) => {
    const f = form.getFieldsValue();
    const val = { ...formDetail, ...f, supcode: v.supcode, supname: v.supname };
    setFormDetail(val);

    form.setFieldsValue(val);
  };

  const handleChoosedUnit = (v) => {
    const f = form.getFieldsValue();
    const val = { ...formDetail, ...f, unitid: v.unitcode, unit: v.unit };
    setFormDetail(val);

    form.setFieldsValue(val);
  };

  const handleConfirm = () => {
    form.validateFields().then((v) => {
      const parm = { ...formDetail ,...form.getFieldsValue()};
      const actions =
        config?.action !== "create"
          ? pkservice.update(parm)
          : pkservice.create(parm);
      // console.log(parm)

      actions
        .then(async (r) => {
          const { pkcode } = r.data;
          message.success("Request success.");

          navigate(from, { replace: true });
          await delay(300);
          console.clear();
        })
        .catch((err) => {
          console.warn(err);
          const data = err?.response?.data;
          message.error(data?.message || "error request");
        });
    });
  };

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const prefixSelector = (
    <Form.Item name="prefixid" noStyle>
      <Select
        style={{
          width: 100,
        }}
        onChange={(v,l)=>          
          opService.optionsGetLastcode({p:'packaging',id:v}).then((res) => {
            let { data } = res;            
            setPrefixCode(data.data)
            const val = {
              ...formDetail,
              prefix: l.label,
            };
            setFormDetail(val)
            
          })}
        options={optionPrefix.map((item) => ({
          value: item.id,
          label: item.value,
        }))}
      ></Select>
    </Form.Item>
  );

  let redcode = <span style={{ color: "red" ,paddingLeft:"15px"}}>{prefixCode!==null?" รหัสล่าสุด "+prefixCode:""} </span>

  const Detail = (
    <>
      <Row gutter={[8, 8]} className="px-2 sm:px-4 md:px-4 lg:px-4">
        <Divider {...dividerProp}>ข้อมูลหลัก</Divider>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={
            config.action !== "edit"
              ? { display: "inline" }
              : { display: "none" }
          }>
          <Form.Item
           
            label={<span>
           <span >Packing Code</span>
           {redcode}
         </span>}
            name="packing_code"
            rules={[{ required: true, message: "Please enter data!" }]}
          >
            <Input
              addonBefore={
                <Text
                  style={{
                    width: 150,
                  }}
                >
                  {prefixSelector}
                </Text>
              }
              placeholder="Enter Packing Code."
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={
            config.action === "edit"
              ? { display: "inline" }
              : { display: "none" }
          }
        >
          <Form.Item label="Packing Code" name="packing_code1">
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item
            label="Packing Name"
            name="packing_name"
            rules={[{ required: true, message: "Please enter data!" }]}
          >
            <Input placeholder="Enter Packing Name." />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item
            label="Packing Name (English)"
            name="packing_nameEN"
          >
            <Input placeholder="Enter Packing Name." />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item label="Express Name" name="express_name">
            <Input placeholder="Enter Express Name." />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item label="ชื่อที่ใช้สั่งซื้อ" name="purchase_name">
            <Input placeholder="Enter Purchase Name." />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item label="Spec Name" name="spec_name">
            <Input placeholder="Enter Spec Name." />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item label="ประเภท">
            <Space.Compact style={{ width: "100%" }}>
              <Input
                readOnly
                placeholder="เลือกประเภท"
                value={
                  !!formDetail.packingtype_id ? formDetail.packingtype_name : ""
                }
              />
              <Button
                type="primary"
                className="bn-primary"
                icon={<SearchOutlined />}
                style={{ minWidth: 40 }}
                onClick={() => setOpenModalPackingType(true)}
              ></Button>
            </Space.Compact>
          </Form.Item>
        </Col>
         <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item label="หน่วยสินค้า" name="unit">
                    <Select
                      size="large"
                      showSearch
                      filterOption={filterOption}
                      placeholder="เลือกหน่วยสินค้า"
                      options={optionUnit.map((item) => ({
                        value: item.value,
                        label: item.value,
                      }))}
                    />
                  </Form.Item>
                </Col>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={
            config.action === "edit"
              ? { display: "inline" }
              : { display: "none" }
          }
        >
          <Form.Item label="สถานะการใช้งาน" name="active_status">
            <Select
              size="large"
              options={[
                {
                  value: "เปิดการใช้งาน",
                  label: <Badge status="success" text="เปิดการใช้งาน" />,
                },
                {
                  value: "ปิดการใช้งาน",
                  label: <Badge status="error" text="ปิดการใช้งาน" />,
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Divider {...dividerProp}>สเป็คกล่อง</Divider>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item name="unit_pc" label="Unit/pc">
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              min="0"
              // max="10"
              step="0.01"
              placeholder="Enter Unit per PC"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item name="wide" label="ความกว้าง">
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              min="0"
              // max="10"
              step="0.01"
              placeholder="ใส่ความกว้าง"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item name="long" label="ความยาว">
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              min="0"
              // max="10"
              step="0.01"
              placeholder="ใส่ความยาว"
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item name="high" label="ความสูง">
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              min="0"
              // max="10"
              step="0.01"
              placeholder="ความสูง"
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Divider {...dividerProp}>ราคา</Divider>
        <Row gutter={[8,8]} className='px-2 sm:px-4 md:px-4 lg:px-4'>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
                <Form.Item label='ราคา' name='price' rules={[ { required: true, message: "Please enter data!", } ]}>
                    <InputNumber min={0} placeholder='Enter Price.' style={{ width: '100%', height: '40px' }} className='input-40' controls={false} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>          
        </Col>      
        </Row> */}
      <Divider {...dividerProp}>อื่นๆ</Divider>
      <Row gutter={[8, 8]} className="px-2 sm:px-4 md:px-4 lg:px-4">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form.Item label="Remark" name="remark">
            <Input.TextArea placeholder="Enter Remark." rows={5} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const SectionTop = (
    <Row
      gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
      className="m-0"
    >
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start">
          <ButtonBack target={from} />
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            icon={<SaveFilled style={{ fontSize: "1rem" }} />}
            type="primary"
            style={{ width: "9.5rem" }}
            onClick={() => {
              handleConfirm();
            }}
          >
            Save
          </Button>
        </Flex>
      </Col>
      {/* <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          {!!code && (
            <ButtonUpload code={code} refer="Packing" showExpire={true} />
          )}
        </Flex>
      </Col> */}
    </Row>
  );

  const SectionBottom = (
    <Row
      gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
      className="m-0"
    >
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start">
          <ButtonBack target={from} />
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            icon={<SaveFilled style={{ fontSize: "1rem" }} />}
            type="primary"
            style={{ width: "9.5rem" }}
            onClick={() => {
              handleConfirm();
            }}
          >
            Save
          </Button>
        </Flex>
      </Col>
    </Row>
  );

  return (
    <div className="packaging-manage xs:px-0 sm:px-0 md:px-8 lg:px-8">
      <Space direction="vertical" className="flex gap-2">
        {SectionTop}
        <Form form={form} layout="vertical" autoComplete="off">
          <Card title={config?.title}>{Detail}</Card>
        </Form>
        {SectionBottom}
      </Space>

      {openModalPackingType && (
        <ModalPackingType
          show={openModalPackingType}
          close={() => {
            setOpenModalPackingType(false);
          }}
          values={(v) => {
            handleChoosedPackingType(v);
          }}
        />
      )}

      {openModalSupplier && (
        <ModalSupplier
          show={openModalSupplier}
          close={() => {
            setOpenModalSupplier(false);
          }}
          values={(v) => {
            handleChoosedSupplier(v);
          }}
        />
      )}

      {openModalUnit && (
        <ModalUnit
          show={openModalUnit}
          close={() => {
            setOpenModalUnit(false);
          }}
          values={(v) => {
            handleChoosedUnit(v);
          }}
        />
      )}
    </div>
  );
};

export default PackingManage;
