import { Badge, Space } from "antd";
import { Button } from "antd";
// import { PrinterOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { TagDocRuncode } from "../../../components/badge-and-tag/ERP";
// import dayjs from 'dayjs';

export const accessColumn = ({ handleEdit, handleDelete, handleView }) => [
  {
    title: "รหัสRuncode",
    key: "id",
    dataIndex: "id",
    align: "left",
    hidden: true,
  },
  {
    title: "Prefix",
    dataIndex: "prefix",
    key: "prefix",
    width: "20%",
    sorter: (a, b) => (a?.prefix || "").localeCompare(b?.prefix || ""),
  },
  {
    title: "Prefix",
    dataIndex: "run_number",
    key: "run_number",
    width: "25%",
    sorter: (a, b) => (a?.run_number || "").localeCompare(b?.run_number || ""),
  },
  {
    title: "รหัส ล่าสุด",
    dataIndex: "last_number",
    key: "last_number",
    width: "25%",
    sorter: (a, b) => (a?.last_number || "").localeCompare(b?.last_number || ""),
  },
  {
      title: "ใช้กับ",
      dataIndex: "doc_sys",
      key: "doc_sys", 
      width: '13%',
      sorter: (a, b) => a.doc_sys.localeCompare(b.doc_sys),
      sortDirections: ["descend", "ascend"],
      render: (data) => <TagDocRuncode result={data} />,
    },
  {
    title: "Action",
    key: "operation",
    width: "10%",
    fixed: "right",
    render: (text, record) => (
      <Space>
        <Button
          icon={<EditOutlined />}
          className="bn-primary-outline"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => handleEdit(record)}
          size="small"
        />
      </Space>
    ),
  },
];

export const Items = {
  id: null,
  runcodecode: null,
  stname: null,
  prename: null,
  idno: null,
  road: null,
  subdistrict: null,
  district: null,
  province: null,
  zipcode: null,
  country: null,
  delidno: null,
  delroad: null,
  delsubdistrict: null,
  deldistrict: null,
  delprovince: null,
  delzipcode: null,
  delcountry: null,
  tel: null,
  fax: null,
  taxnumber: null,
  email: null,
  active_status: "Y",
};
