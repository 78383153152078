import React from 'react'
import { Tag } from "antd" 
import {  IoIosCloseCircle  } from "react-icons/io";
import { BiPackage } from "react-icons/bi";
import { BsBoxes } from "react-icons/bs";
import { FaBottleDroplet, FaBoxesPacking } from "react-icons/fa6"; 
import {DropboxOutlined} from "@ant-design/icons";

function TagDocRuncode({result = ""}) {
  let elementToRender;

  switch (result?.toLowerCase()) {
    case 'pk':
      elementToRender = <Tag icon={<FaBottleDroplet />} color="#ababab" className='flex gap-2 justify-center items-center' > Packaging </Tag>;
      break;
    case 'pc':
      elementToRender = <Tag icon={<DropboxOutlined />} color="#ababab" className='flex gap-2 justify-center items-center' > Packing </Tag>;
      break;
      case 'fg':
      elementToRender = <Tag icon={<BsBoxes />} color="#87d068" className='flex gap-2 justify-center items-center' > Finished Goods </Tag>;
      break;
      case 'pg':
      elementToRender = <Tag icon={<BiPackage />} color="#87d068" className='flex gap-2 justify-center items-center' > Packing Goods </Tag>;
      break;
      case 'ig':
      elementToRender = <Tag icon={<IoIosCloseCircle />} color="#ffab47" className='flex gap-2 justify-center items-center' > Ingredient </Tag>;
      break;
      case 'rw':
      elementToRender = <Tag icon={<IoIosCloseCircle />} color="#ffab47" className='flex gap-2 justify-center items-center' > Raw Material </Tag>;
      break;
    default:
      elementToRender = <Tag  className='flex gap-2 justify-center items-center' > Not found </Tag>;
  }
  return <>{elementToRender}</>
}
// { value: "PK", label: "Packaging" },
//               { value: "PC", label: "Packing" },
//               { value: "FG", label: "Finished Goods" },
//               { value: "PG", label: "Packing Goods" },
//               { value: "IG", label: "Ingredient" },
//               { value: "RW", label: "Raw Material" },

export default TagDocRuncode