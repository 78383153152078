import { Typography } from "antd";
/** get items column */

export const columns = ({handleChoose})=>{
  const Link = Typography.Link;
  const LChoose = ({children, record}) => (
    <Link 
    className="text-select" 
    onClick={()=>handleChoose(record)} 
    disabled={record?.active_status === 'ปิดการใช้งาน'} >{children}</Link>
  );
  return [
    {
      title: "PG Code",
      key: "pg_code",
      dataIndex: "pg_code", 
      width:"25%",
      render: (v, record) => <LChoose record={record}>{v}</LChoose>
    },
    {
      title: "PG Name",
      dataIndex: "pg_name",
      key: "pg_name",
      width:"25%",
      render: (v, record) => <LChoose record={record}>{v}</LChoose>
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width:"25%",
      render: (v, record) => <LChoose record={record}>{v}</LChoose>
    },
    {
      title: "Customer PG Name",
      dataIndex: "pg_stname_cus",
      key: "pg_stname_cus",
      width:"25%",
      render: (v, record) => <LChoose record={record}>{v}</LChoose>
    },
  ]
};