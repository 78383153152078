/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';

import { Modal, Card, Table, message, Form, Button } from "antd";
import { Row, Col, Space, Spin, Flex } from "antd";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useForm } from 'antd/es/form/Form';

import { columns } from "./model";
import OptionService from '../../../../service/ERP/Options.service';
import { BsUiChecks } from "react-icons/bs";


export default function ModalPG({show, close, values, selected=[],cuscode}) {
    const optionService = OptionService();
    const [form] = useForm();
    const inputRef = useRef(null);
    const [modalData, setModalData] = useState([]);
    const [modalDataWrap, setModalDataWrap] = useState([]);

    const [openModal,  setOpenModel] = useState(show);
    const [loading,  setLoading] = useState(true);
    const [onLoaded,  setOnLoaded] = useState(false);

    const [rowKeySelect, setRowKeySelect] = useState([]);

    const [itemsTypeData, setItemsTypeData] = useState([]);

    /** handle logic component */
    const handleClose = () =>{ 
        setTimeout( () => { close(false);  }, 140);
        
        //setTimeout( () => close(false), 200 );
    }

    // const handleConfirm = () => {
    //     // console.log(itemsList); 
    //     // values([...itemsList, ...selected]);
    //     // setItemsList([]);
    //     setOpenModel(false);
    // }

    const handleSearch = (value) => {
        if(!!value){    
            const f = modalData.filter( d => ( 
                (d.pg_code?.toLowerCase().includes(value?.toLowerCase())) || 
                (d.product_name?.toLowerCase().includes(value?.toLowerCase())
        ) ) );
             
        setModalDataWrap(f);            
        } else { 
            setModalDataWrap(modalData);            
        }

    }

    const handleChoose = (value) => {
        values(value);
        setOpenModel(false);
    }

    /** setting initial component */ 
    const column = columns({handleChoose});

    useEffect( () => {
        if( onLoaded ){
            setLoading(true);
            setTimeout( ()=>{
                const value = inputRef.current?.input.value;
                handleSearch(value); 
                
                setLoading(false);
            }, 200)           
        }
    }, [itemsTypeData])

    useEffect( () => {
        const onload = () =>{
            setLoading(true);
            // alert(cuscode)
            optionService.optionsPG({p:'jo',cuscode:cuscode}).then(async (res) => {
                let { data } = res.data; 
                setModalData(data);
                setModalDataWrap(data);
                // console.log(modalData, data) 
                const keySeleted = selected.map( m => m.pg_code );

                setRowKeySelect([...keySeleted]);
             
            })
            .catch((err) => { 
                message.error("Request error!");

                // setLoading(false);
            })
            .finally( () => setTimeout( () => { setLoading(false); setOnLoaded(true) }, 400));
        }        
        if( !!openModal ){
            onload();

            // console.log("modal-packages")        
        } 
    }, [openModal]);

    return (
        <>
        <Modal
            open={openModal}
            title="เลือกสินค้า"
            afterClose={() => handleClose() }
            onCancel={() => setOpenModel(false) } 
            maskClosable={false}
            style={{ top: 20 }}
            width={800}
            className='modal-customers'
            footer={(
                <Row>
                    <Col span={24}>
                        {/* Ignore */}
                    </Col>
                    <Col span={24}>
                        <Flex justify='flex-end'>
                            <Button  className='bn-center bn-primary' icon={<BsUiChecks />} onClick={()=>handleChoose()}> Confirm </Button>
                        </Flex>
                    </Col> 
                </Row>
            )}
        >
            <Spin spinning={loading} >
                <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}}  >
                    <Card style={{backgroundColor:'#f0f0f0' }}>
                        <Form form={form} layout="vertical" autoComplete="off" >
                            <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
                                <Col span={24}>
                                    <Form.Item label="ค้นหา"  >
                                        <Input ref={inputRef} suffix={<SearchOutlined />} onChange={ (e) => { handleSearch(e.target.value) } } placeholder='ค้นหาชื่อ หรือ รหัส'/>
                                    </Form.Item>                        
                                </Col> 
                            </Row> 
                        </Form>
                    </Card>
                    <Card  style={{minHeight:'60vh'}}>
                        <Table
                            bordered
                            dataSource={modalDataWrap}
                            // rowSelection={itemSelection}
                            columns={column}
                            rowKey="pg_code"
                            pagination={{ 
                                total:modalDataWrap?.length || 0, 
                                showTotal:(_, range) => `${range[0]}-${range[1]} of ${modalDataWrap?.length || 0} items`,
                                defaultPageSize:25,
                                pageSizeOptions:[25,35,50,100]
                            }} 
                            size='small'
                        /> 
                    </Card>                    
                </Space> 
            </Spin>
        </Modal>    
        </>
    )
}
