import { Tooltip, Typography } from "antd";
import { TagActiveStatus } from "../../../badge-and-tag/ERP";
import dayjs from 'dayjs';
/** get items column */
export const columnsFG = ({handleChoose})=>{
    const Link = Typography.Link;
    const LChoose = ({children, record}) => (
      <Link 
      className="text-select" 
      onClick={()=>handleChoose(record)} 
      disabled={record?.active_status === 'ปิดการใช้งาน'} >{children}</Link>
    );
    return [
      {
        title: "FG Code",
        key: "fg_code",
        dataIndex: "fg_code", 
        hidden: true,
      },
      {
        title: "FG Name",
        key: "fg_name",
        dataIndex: "fg_name", 
        render: (v, record) => <LChoose record={record}>{v}</LChoose>
      },
      {
        title: "Status",
        dataIndex: "active_status",
        key: "active_status",
        width: 84,
        render: (v, record) => <LChoose record={record}><TagActiveStatus result={v} /></LChoose>
      },
      {
        title: "Updated By",
        dataIndex: "updated_name",
        key: "updated_name",
        render: (v, record) => <LChoose record={record}>{v}</LChoose>
      },
      {
        title: "Updated Date",
        dataIndex: "updated_date",
        key: "updated_date",
        render: (v, record) => <LChoose record={record}>{dayjs(v).format("DD/MM/YYYY")}</LChoose>
      },
    ]
};