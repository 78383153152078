import { Button, Flex, Popconfirm, Tag, Tooltip, Typography } from "antd";
import { TagActiveStatus } from "../../../badge-and-tag/ERP";
import dayjs from 'dayjs';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { capitalized } from "../../../../utils/util";
/** get items column */
export const columnsPacking = ({handleChoose})=>{
    const Link = Typography.Link;
    const LChoose = ({children, record}) => (
      <Link 
      className="text-select" 
      onClick={()=>handleChoose(record)} 
      disabled={record?.active_status === 'ปิดการใช้งาน'} >{children}</Link>
    );
    return [
      {
        title: "Packing Name",
        key: "packing_name",
        dataIndex: "packing_name",
        width:200,
        render: (v, record) => <LChoose record={record}>{v}</LChoose>
      },
      {
        title: "Spec ",
        key: "spec_name",
        dataIndex: "spec_name",
        width:180,
        render: (v, record) => <LChoose record={record}>{v}</LChoose>
      },
      {
        title: "Packing Type",
        dataIndex: "pktype",
        key: "pktype",
        render: (h, record)=>!!h && (
          <LChoose record={record}>
            <Tag color="#3b5999" >{capitalized(h || "")}</Tag>
          </LChoose>
        ),
      },
    ]
};

export const columnsPkType = ({handleChoose, handleEdit, handleDeleted})=>{
  const Link = Typography.Link;
  const LChoose = ({children, record}) => (
    <Link 
    className="text-select" 
    onClick={()=>handleChoose(record)} 
    disabled={record?.active_status === 'ปิดการใช้งาน'} >{children}</Link>
  );
  return [
    {
      title: "Packing Type",
      key: "packingtype_name",
      dataIndex: "packingtype_name", 
      render: (v, record) => <LChoose record={record}>{v}</LChoose>
    },
    {
      title: "Status",
      dataIndex: "active_status",
      key: "active_status",
      width: 84,
      render: (v, record) => <LChoose record={record}><TagActiveStatus result={v} /></LChoose>
    },
    {
      title: "Updated By",
      dataIndex: "updated_name",
      key: "updated_name",
      render: (v, record) => <LChoose record={record}>{v}</LChoose>
    },
    {
      title: "Updated Date",
      dataIndex: "updated_date",
      key: "updated_date",
      render: (v, record) => <LChoose record={record}>{dayjs(v).format("DD/MM/YYYY")}</LChoose>
    },
    {
      title: "",
      width: 50,
      render: (v, record) => (
        <Flex gap={3}>
          <Button
            icon={<EditOutlined />} 
            className='bn-primary-outline'
            style={{ cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            onClick={(e) => handleEdit(record) }
            size="small"
          />
          {/* <Popconfirm 
            placement="topRight"
            title="Sure to delete?"  
            description="Are you sure to delete this sample preparation?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => handleDeleted(record)}
          >
            <Button
              icon={<DeleteOutlined />}
              danger
              style={{ cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center' }} 
              size="small"
            />
          </Popconfirm> */}
        </Flex>
      )
    },
  ]
};

