/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Badge } from "antd";
import { Collapse, Form, Flex, Row, Col, Space, Select, Card, message } from "antd";
import { Input, Button, Table, Typography } from "antd";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { accessColumn } from "./model";
// import OptionService from "../../../service/ERP/Options.service";
// import dayjs from 'dayjs';
import JobOrderservice from "../../../service/ERP/JobOrder.service.js";
// const opService = OptionService();
const joservice = JobOrderservice();
const mngConfig = {
  title: "",
  textOk: null,
  textCancel: null,
  action: "create",
  code: null,
};
const JobOrderAccess = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [accessData, setAccessData] = useState([]);
  const [activeSearch, setActiveSearch] = useState([]);

  const handleSearch = () => {
    form.validateFields().then((v) => {
      const data = { ...v };
      joservice
        .search(data, { ignoreLoading: Object.keys(data).length !== 0 })
        .then((res) => {
          const { data } = res.data;

          setAccessData(data);
        })
        .catch((err) => {
          console.log(err);
          message.error("Request error!");
        });
    });
  };

  const handleClear = () => {
    form.resetFields();

    handleSearch();
  };

  const hangleAdd = () => {
    navigate("manage/create", {
      state: {
        config: {
          ...mngConfig,
          title: "Create Job Order",
          action: "create",
        },
      },
      replace: true,
    });
  };

  const handleSave = (data) => {
    // setManageConfig({...manageConfig, title:"แก้ไข Sample Request", action:"edit", code:data?.srcode});
    navigate("manage/edit", {
      state: {
        config: {
          ...mngConfig,
          title: "Edit Job Order",
          action: "edit",
          code: data?.jocode,
        },
      },
      replace: true,
    });
  };

  const handleView = (data) => {
    const newWindow = window.open("", "_blank");
    newWindow.location.href = `/dln-print/${data.dncode}`;
  };

  const handleDelete = (data) => {
    // startLoading();
    // ctmService.deleted(data?.dncode).then( _ => {
    //     const tmp = accessData.filter( d => d.dncode !== data?.dncode );
    //     setAccessData([...tmp]);
    // })
    // .catch(err => {
    //     console.log(err);
    //     message.error("Request error!");
    // });
  };

  const init = async () => {
    getData({});
  };

  useEffect(() => {
    init();
    return async () => {
      //console.clear();
    };
  }, []);

  const getData = () => {
    handleSearch();
  };

  const FormSearch = (
    <Collapse
      size="small"
      onChange={(e) => {
        setActiveSearch(e);
      }}
      bordered={false}
      activeKey={activeSearch}
      items={[
        {
          key: "1",
          label: (
            <>
              <SearchOutlined />
              <span> ค้นหา</span>
            </>
          ),
          children: (
            <>
              <Form form={form} layout="vertical" autoComplete="off">
                <Row gutter={[8, 8]}>
                  {/* <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Product Code"
                      name="product_code"
                      onChange={handleSearch}
                    >
                      <Input placeholder="Enter Product Code" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Product Thai Name"
                      name="product_name"
                      onChange={handleSearch}
                    >
                      <Input placeholder="Enter Product Thai Name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Product Type"
                      name="type_name"
                      onChange={handleSearch}
                    >
                      <Select
                        size="large"
                        showSearch
                        placeholder="Select Product Type"
                        onChange={handleSearch}
                        options={[
                          {
                            value: "FG",
                            label: "Finished Goods",
                          },
                          {
                            value: "PG",
                            label: "Packing Goods",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col> */}
                  {/* <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item
                      label="Express Code"
                      name="express_code"
                      onChange={handleSearch}
                    >
                      <Input placeholder="Enter Express Code." />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item label="มาตรฐาน Halal" name="halal">
                      <Select
                        placeholder="สถานะมาตรฐาน Halal"
                        style={{ height: 38 }}
                        onChange={handleSearch}
                        options={[
                          {
                            value: "N",
                            label: <Badge status="error" text="ไม่มี" />,
                          },
                          {
                            value: "Y",
                            label: <Badge status="success" text="มี" />,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                    {/* Ignore */}
                  </Col>
                  <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                    <Flex justify="flex-end" gap={8}>
                      <Button
                        type="primary"
                        size="small"
                        className="bn-action"
                        icon={<SearchOutlined />}
                        onClick={() => handleSearch()}
                      >
                        ค้นหา
                      </Button>
                      <Button
                        type="primary"
                        size="small"
                        className="bn-action"
                        danger
                        icon={<ClearOutlined />}
                        onClick={() => handleClear()}
                      >
                        ล้าง
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              </Form>
            </>
          ),
          showArrow: false,
        },
      ]}
    />
  );
  const column = accessColumn({ handleSave, handleDelete, handleView });

  const TitleTable = (
    <Flex className="width-100" align="center">
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start" align="center">
          <Typography.Title className="m-0 !text-zinc-800" level={3}>
            List of Job Order
          </Typography.Title>
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            size="small"
            className="bn-action bn-center bn-primary-outline justify-center"
            icon={<MdOutlineLibraryAdd style={{ fontSize: ".9rem" }} />}
            onClick={() => {
              hangleAdd();
            }}
          >
            Create Product
          </Button>
        </Flex>
      </Col>
    </Flex>
  );
  return (
    <div className="product-access">
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", position: "relative" }}
      >
        <Card>
          {FormSearch}
          <br></br>
          <Row gutter={[8, 8]} className="m-0">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table
                title={() => TitleTable}
                size="small"
                rowKey="product_code"
                columns={column}
                dataSource={accessData}
              />
            </Col>
          </Row>
        </Card>
      </Space>
    </div>
  );
};

export default JobOrderAccess;
