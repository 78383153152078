import { Tooltip, Space,Button } from "antd";
// import { Image } from "antd";
// import { PrinterOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
// import { 
//   BACKEND_URL_MAIN
//  } from '../../../utils/util';

// import dayjs from 'dayjs';

export const accessColumn = ({ handleEdit, handleDelete, handleView }) => [
  // {
  //   title: "รูปประกอบ",
  //   dataIndex: "file",
  //   key: "file",
  //   width: 120,
  //   // align: "center",
  //   render: (im, rec) => 
  //     {
  //       const img = (!!rec.file_name ? `/uploads/ERP/pg/` + rec.file_name : `/logo.png`
  //       );
  //       return <>
  //       <Image
  //     style={{ borderRadius: 10 }}
  //     preview={false}
  //     height={30}
  //     alt={`Image ${rec.file_name}`}
  //     src={`${BACKEND_URL_MAIN}` + img}
  //   />
  //   </>
  //   },
  // },
  {
    key: "id",
    dataIndex: "id",
    align: "left",
    hidden: true,
  },
  {
    title: "PG Code",
    dataIndex: "pg_code",
    key: "pg_code",
    sorter: (a, b) => (a?.pg_code || "").localeCompare(b?.pg_code || ""),
    width: 140,
  },
  {
    title: "PG Name (TH)",
    dataIndex: "pg_name",
    key: "pg_name",
    width: "25%",
    sorter: (a, b) => (a?.pg_name || "").localeCompare(b?.pg_name || ""),
    ellipsis: { showTitle: false, },
    render: (v) => <Tooltip placement="topLeft" title={v}>{v}</Tooltip>,
  },
  {
    title: "Customer PG Name",
    dataIndex: "pg_stname_cus",
    key: "pg_stname_cus",
    width: "25%",
    sorter: (a, b) => (a?.pg_stname_cus || "").localeCompare(b?.pg_stname_cus || ""),
    ellipsis: { showTitle: false, },
    render: (v) => <Tooltip placement="topLeft" title={v}>{v}</Tooltip>,
  },
  // {
  //   title: "PG Name (EN)",
  //   dataIndex: "pg_nameEN",
  //   key: "pg_nameEN",
  //   width: "25%",
  //   sorter: (a, b) => (a?.pg_nameEN || "").localeCompare(b?.pg_nameEN || ""),
  //   ellipsis: { showTitle: false, },
  //   render: (v) => <Tooltip placement="topLeft" title={v}>{v}</Tooltip>,
  // },
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    sorter: (a, b) => (a?.code || "").localeCompare(b?.code || ""),
    width: 140,
  },
  // {
  //   title: "ราคา",
  //   dataIndex: "price",
  //   key: "price",
  //   className: "!pe-4",
  //   align:'right',
  //   sorter: (a, b) => (a?.price || "").localeCompare(b?.price || ""),
  //   render:(v)=>formatMoney( Number(v || 0))
  // },
  {
    title: "Action",
    key: "operation",
    width: "60px",
    fixed: "right",
    render: (text, record) => (
      <Space>
        <Button
          icon={<EditOutlined />}
          className="bn-primary-outline"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => handleEdit(record)}
          size="small"
        />
      </Space>
    ),
  },
];

export const PG = {
  id: null,
  pg_code: null,
  pg_name: null,
  pg_nameEN: null,
  pg_type: null,
  pg_stname_cus: null,
  code: null,
  unit: null,
  weight_nw: 0,
  weight_gw: 0,
  feature: null,
  remarks: null,
  active_status: null,
};
