import React, {useState, useEffect} from 'react';

import { Modal, Card, Table, message, Form, Spin } from "antd";
import { Row, Col, Space, Drawer } from "antd";
import { Input, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useForm } from 'antd/es/form/Form';

import { ModalBrandManage } from './modal-brand.js';

import { brandColumn } from "./model.js";
import BrandService from '../../../../service/ERP/Brand.service.js'; 
import OptionService from '../../../../service/ERP/Options.service.js';

export default function ModalBrand({show, close, values, selected}) {
    const [form] = useForm(); 
    
    const [brandData, setBrandData] = useState([]);
    const [brandDataWrap, setBrandDataWrap] = useState([]);

    const [openModal,  setOpenModel] = useState(show);
    const [loading,  setLoading] = useState(true);

    const [openManage,  setOpenManage] = useState(false);  
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const optionService = OptionService();
    const brandService = BrandService();
    /** handle logic component */
    const handleClose = () =>{ 
        setTimeout( () => { close(false);  }, 140);
        
    }

    const handleSearch = (value) => {
        if(!!value){    
            const f = brandData.filter( d => (                 
                (d.brand_code?.toLowerCase().includes(value?.toLowerCase())
        ) ) );
             
            setBrandDataWrap(f);            
        } else { 
            setBrandDataWrap(brandData);            
        }

    }

    const handleChoose = (value) => {
        values(value);
        setOpenModel(false);
    }

    const manageSubmit = ( v ) => {
        // setOpenManage(false);
        setLoading(true); 

        const parm = {...v}; 
        const action = brandService.create( parm );        

        action.then( _ =>  {
            search();
            message.success("สร้างยี่ห้อสำเร็จ.");
        }).catch( err => {
            console.warn(err);
            const data = err?.response?.data;
            message.error( data?.message || "error request");
        })
        .finally( () => {
            setTimeout( () => { setLoading(false) }, 300);
        });        

        // setLoading(true);
        setOpenManage(false);
    }

    /** setting initial component */ 
    const column = brandColumn({handleChoose});
    const search = () =>{
        setLoading(true);
        optionService.optionsBrand().then((res) => {
            let { data } = res.data; 
            setBrandData(data);
            setBrandDataWrap(data);
            // console.log(modalData, data) 
        })
        .catch((err) => { 
            console.warn(err);
            const data = err?.response?.data;
            message.error( data?.message || "error request");  
            // setLoading(false);
        })
        .finally( () => setTimeout( () => { setLoading(false) }, 400));
    }

    useEffect( () => {
        if( !!openModal ){
            search();   
        } 
    }, [openModal]);

    const handleResize = () => {
      setIsSmallScreen(window.matchMedia('(max-width: 767px)').matches);
    };

    useEffect(() => {
      handleResize(); // Set initial screen size

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // Empty dependency array ensures the effect runs once on mount
 
    return (
        <>
        <Modal
            open={openModal}
            title="Select Brand"
            afterClose={() => handleClose() }
            onCancel={() => setOpenModel(false) } 
            maskClosable={false}
            style={{ top: 20 }}
            width={800}
            className='modal-brand'
        >
            <Spin spinning={loading} >
                <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}}  >
                    <Card style={{backgroundColor:'#f0f0f0'}}>
                        <Form form={form} layout="vertical" autoComplete="off" >
                            <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
                                <Col span={24}>
                                    <Form.Item label="ค้นหา"  >
                                        <Input suffix={<SearchOutlined />} onChange={ (e) => { handleSearch(e.target.value) } } placeholder='ค้นหาชื่อยี่ห้อ'/>
                                    </Form.Item>                        
                                </Col> 
                            </Row> 
                            <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
                                <Col span={24}>
                                    <Typography.Link onClick={()=>{ setOpenManage(true); }} className='ps-1'>
                                        <span className='hover:underline underline-offset-1'>Create Brand</span>
                                    </Typography.Link> 
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                    <Card style={{minHeight:'60vh' }}>
                        <Table  
                            bordered
                            dataSource={brandDataWrap}
                            columns={column}
                            rowKey="brand_code"
                            pagination={{ 
                                total:brandDataWrap.length, 
                                showTotal:(_, range) => `${range[0]}-${range[1]} of ${brandData.length} items`,
                                defaultPageSize:25,
                                pageSizeOptions:[25,35,50,100]
                            }}
                            scroll={{ x: 'max-content', y:400 }} 
                            size='small'
                        /> 
                    </Card>
                    { openManage &&
                    <Drawer
                        destroyOnClose={true}
                        title="Create Brand"
                        width={isSmallScreen ? '100%' : '50vw'}
                        className='custom-drawer-class'
                        onClose={()=>{setOpenManage(false)}}
                        open={openManage} 
                        styles={{ body: { padding: '0px 24px 8px' } }}
                        getContainer={() => document.querySelector(".modal-brand")}
                    >
                        <ModalBrandManage submit={(val)=>manageSubmit(val)} /> 
                    </Drawer>}
                </Space> 
            </Spin>

        </Modal>    
        </>
    )
}
