import { Typography } from "antd";


/** get items column */
export const brandColumn = ({handleChoose})=>{
    const Link = Typography.Link;
    return [
      // {
      //   title: "รหัสลูกค้า",
      //   key: "cuscode",
      //   dataIndex: "cuscode", 
      //   render: (v, record) => <Link className="text-select" onClick={()=>handleChoose(record)}>{v}</Link>
      // },
      {
        title: "ชื่อยี่ห้อ",
        dataIndex: "brand_name",
        key: "brand_name",
        render: (v, record) => <Link className="text-select" onClick={()=>handleChoose(record)}> {v}</Link>
      } 
    ]
  };