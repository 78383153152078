import { Tooltip, Space,Button } from "antd";
// import { Image } from "antd";
// import { PrinterOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditableRow, EditableCell } from "../../../components/table/TableEditAble";
import { EditOutlined } from "@ant-design/icons";
// import { 
//   BACKEND_URL_MAIN
//  } from '../../../utils/util';

import dayjs from 'dayjs';
import { formatMoney } from "../../../utils/util";
export const componentsEditable = {
  body: { row: EditableRow, cell: EditableCell },
};

export const accessColumn = ({ handleSave, handleDelete, handleView }) => [
  {
    title: "Job",
    dataIndex: "jocode",
    key: "jocode",
    sorter: (a, b) => (a?.jocode || "").localeCompare(b?.jocode || ""),
    width: 140,
  },
  {
      title: "JO Date",
      dataIndex: "jodate",
      key: "jodate", 
      width: 160,
      sorter: (a, b) => (a.jodate).localeCompare(b.jodate),
      render: (v) => dayjs(v).format("DD/MM/YYYY"),
    },
  {
    title: "Order",
    dataIndex: "pi_no",
    key: "pi_no",
    width: "25%",
    sorter: (a, b) => (a?.pi_no || "").localeCompare(b?.pi_no || ""),
    ellipsis: { showTitle: false, },
    render: (v) => <Tooltip placement="topLeft" title={v}>{v}</Tooltip>,
  },
  {
    title: "Brand",
    dataIndex: "brand_name",
    key: "brand_name",
    width: "25%",
    sorter: (a, b) => (a?.brand_name || "").localeCompare(b?.brand_name || ""),
    ellipsis: { showTitle: false, },
    render: (v) => <Tooltip placement="topLeft" title={v}>{v}</Tooltip>,
  },
  {
    title: "ETL",
    dataIndex: "etl",
    key: "etl", 
    width: 160,
    sorter: (a, b) => (a.etl).localeCompare(b.etl),
    render: (t,v) => (!!v?.etl ? dayjs(t).format("DD/MM/YYYY") : ""),
  },
  {
    title: "Action",
    key: "operation",
    width: "60px",
    fixed: "right",
    render: (text, record) => (
      <Space>
        <Button
          icon={<EditOutlined />}
          className="bn-primary-outline"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => handleSave(record)}
          size="small"
        />
      </Space>
    ),
  },
];

/** get column for edit table parameter */
export const detailColumns = ({ handleAction }) => {
  return [
    {
      title: "No.",
      key: "seq",
      align: "center",
      width: 80,
      render: (_, record, idx) => <span key={record?.pg_code}>{idx + 1}</span>,
    },
    {
      title: "PG Code",
      align: "left",
      width: 140,
      key: "pg_code",
      dataIndex: "pg_code",
      editable: true,
      required: false,
      type: 'modal-select',
      className:"field-edits pe-2"
    },
    {
      title: "PG Name",
      align: "left",
      editable: true,
      required: true,
      key: "pg_name",
      dataIndex: "pg_name",
      className:"field-edit pe-2",
      type:'input',
    },
    {
      title: "FG Name",
      align: "left",
      key: "fg_code",
      dataIndex: "fg_name",
    },
    {
      title: "Code",
      align: "left",
      key: "code",
      width: 120,
      dataIndex: "code",
    },
    {
      title: (<>จำนวนกล่อง</>),
      align: "right",
      width: 120,
      editable: true,
      required: false,
      key: "carton",
      dataIndex: "carton",
      className:"field-edit pe-2",
      type:'input',
    },
    {
      title: (<>Unit/cs</>),
      align: "right",
      width: 120,
      editable: true,
      required: false,
      key: "unit_cs",
      dataIndex: "unit_cs",
      className:"field-edit pe-2",
      type:'input',
    },
    {
      title: (<>จำนวน Unit</>),
      align: "right",
      width: 120,
      key: "total_unit",
      dataIndex: "total_unit",
      className:"field-edit pe-2",
      render:(t,v) => formatMoney(Number(v.carton) * Number(v.unit_cs)),
    },
    {
      title: "หมายเหตุ",
      align: "center",
      key: "detail_remark",
      dataIndex: "detail_remark",
      editable: true,
      required: true,
      className:"field-edit pe-2",
      type:'input',
    },
    {
      title: "ตัวเลือก",
      align: "center",
      key: "operation",
      dataIndex: "operation",
      render: (_, record, idx) => handleAction(record),
      width: '90px',
      fixed: 'right',
    },
  ].filter((item) => !item.hidden);
}

export const columnsDetailsEditable = (handleSave,handleModalSelect, {handleAction, nameOption} ) =>{
  const col = detailColumns({handleAction, nameOption});
  return col.map((col, ind) => {
      if (!col.editable) { return col; }
      return {
          ...col,
          onCell: (record) => {
            // console.log(record);
            return {
              record,
              editable: col.editable,
              dataIndex: col.dataIndex,
              title: col.title,
              handleSave,
              fieldType: !!col?.textArea,
              required: !!col?.required,
              type: col?.type || 'input',
              autocompleteOption: col.autocompleteOption,
              modalSelect: handleModalSelect
            }
          },
      };
  }); 
}

export const Product = {
  id: null,
  product_code: null,
  product_name: null,
  product_nameEN: null,
  product_type: null,
  product_stname_cus: null,
  code: null,
  unit: null,
  weight_nw: 0,
  weight_gw: 0,
  feature: null,
  remarks: null,
  active_status: null,
};
