import {
  // AppstoreOutlined,
  FileTextFilled,
  FileDoneOutlined, 
  FileProtectOutlined,
  HddOutlined,
  // InboxOutlined,
  TeamOutlined,
  DropboxOutlined , 
  ExperimentOutlined,
  ReconciliationFilled,
  // CodeSandboxOutlined,
} from "@ant-design/icons";
import { BsBoxes } from "react-icons/bs";
 
import { TbReportMoney } from "react-icons/tb";
import { FaBottleDroplet, FaBoxesPacking } from "react-icons/fa6"; 
import { RiTeamFill } from "react-icons/ri";
import { MdOutlineDashboard } from "react-icons/md";
import { FaTruckLoading,FaTag } from "react-icons/fa";
// import { IoLogoModelS } from "react-icons/io";
import { BiPackage } from "react-icons/bi";

import { 
  MdOutlineGroup, 
  MdRequestQuote 
} from "react-icons/md";

let rd_auth = ['admin','rd','md']
let sales_auth = ['admin','rd','md','sale']
let production_auth = ['admin','rd','md']

let _nav = [
  {
    title: "DATA",
    type: "group",
  },
  {
    title: "Dashboard",
    icon: <MdOutlineDashboard className="nav-ico" />,
    to: "/dashboard",
  },  
  {
    title: "ERP System",
    type: "group",
    role: sales_auth,
  },  
  {
    title: "Job Order",
    icon: <FileDoneOutlined className="nav-ico" />,
    to: "/ERP/job-order",
    role: sales_auth,
  },
  {
    title: "ERP Master",
    type: "group",
    role: sales_auth,
  },  
  // {
  //   title: "Items",
  //   icon: <FileDoneOutlined className="nav-ico" />,
  //   to: "/ERP/items",
  //   role: sales_auth,
  // },
  {
    title: "Packing Goods",
    icon: <BiPackage className="nav-ico" />,
    to: "/ERP/packing-goods",
    role: sales_auth,
  }, 
  {
    title: "Finished Goods",
    icon: <BsBoxes className="nav-ico" />,
    to: "/ERP/finished-goods",
    role: sales_auth,
  },
  {
    title: "Packaging",
    icon: <FaBottleDroplet className="nav-ico" />,
    to: "/ERP/packaging",
    role: sales_auth,
  },
  {
    title: "Packing",
    icon: <DropboxOutlined  className="nav-ico" />,
    to: "/ERP/packing-master",
    role: sales_auth,
  },
  {
    title: "Customers",
    icon: <RiTeamFill className="nav-ico" />,
    to: "/ERP/customer",
    role: sales_auth,
  },
  {
    title: "Runcode",
    icon: <FaTag className="nav-ico" />,
    to: "/ERP/runcode",
    role: sales_auth,
  }, 
  {
    title: "Unit",
    icon: <HddOutlined className="nav-ico" />,
    to: "/ERP/unit",
    role: sales_auth,
  }, 
  // {
  //   title: "Itemtype",
  //   icon: <TiThLarge className="nav-ico" />,
  //   to: "/ERP/item_type",
  //   role: sales_auth,
  // }, 
  {
    title: "RD System",
    type: "group",
    role: rd_auth,
  },
  {
    title: "Sample Request",
    icon: <FileTextFilled className="nav-ico" />, 
    to: "/sample-request",
    role: rd_auth,
  },
  {
    title: "Sample Preparation",
    icon: <ReconciliationFilled className="nav-ico" />, 
    to: "/sample-preparation",
    role: rd_auth,
  },
  {
    title: "Pilot Scale",
    icon: <ExperimentOutlined className="nav-ico" />, 
    to: "/pilot-scale",
    role: rd_auth,
  },
  {
    title: "Delivery Note",
    icon: <FileProtectOutlined  className="nav-ico"/>, 
    to: "/delivery-note",
    role: rd_auth,
  },
  {
    title: "Estmate Cost",
    icon: <TbReportMoney  className="nav-ico"/>, 
    to: "/estimation",
    role: rd_auth,
  },
  {
    title: "Packing Set",
    icon: <FaBoxesPacking  className="nav-ico"/>,
    to: "/packing-set",
    role: rd_auth,
  },
  {
    title: "Quotation",
    icon: <MdRequestQuote  className="nav-ico"/>,
    to: "/quotation",
    role: rd_auth,
  },



  {
    title: "RD Master",
    type: "group",
    role: rd_auth,
  },  
  {
    title: "Items",
    icon: <FileDoneOutlined className="nav-ico" />,
    to: "/items",
    role: rd_auth,
  },
  {
    title: "Packaging",
    icon: <FaBottleDroplet className="nav-ico" />,
    to: "/packaging",
    role: rd_auth,
  },
  {
    title: "Shipping Type",
    icon: <FaTruckLoading className="nav-ico" />,
    to: "/shipping-type",
    role: rd_auth,
  },

  {
    title: "Unit",
    icon: <HddOutlined className="nav-ico" />,
    to: "/unit",
    role: rd_auth,
  },
  {
    title: "Customers",
    icon: <RiTeamFill className="nav-ico" />,
    to: "/customers",
    role: rd_auth,
  },
  {
    title: "Suppliers",
    icon: <MdOutlineGroup className="nav-ico" />,
    to: "/suppliers",
    role: rd_auth,
  },   
  {
    title: "Admin",
    type: "group",
    role: ['admin','md'],
  },  
  {
    title: "User",
    icon: <TeamOutlined className="nav-ico" />,
    to: "/user",
    role: ['admin','md'],
  },
  // {
  //   title: "File",
  //   icon: <TeamOutlined className="nav-ico" />,
  //   to: "/file-control",
  // },
  // {
  //   title: "Sample Request [ Dev ]",
  //   icon: <FileTextOutlined />,
  //   to: "/sample-request",
  // },
];

export default _nav;
