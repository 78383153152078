import { Badge, Space, Typography } from "antd";
import { Button } from "antd";
// import { PrinterOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { formatMoney } from "../../../utils/util";
import { EditableRow, EditableCell } from "../../../components/table/TableEditAble";

/** export component for edit table */
export const componentsEditable = {
  body: { row: EditableRow, cell: EditableCell },
};

export const accessColumn = ({ handleEdit, handleDelete, handleView }) => [
  {
    title: "รหัสลูกค้า",
    key: "cuscode",
    dataIndex: "cuscode",
    align: "left",
    width: 110,
    sorter: (a, b) => (a?.cuscode || "").localeCompare(b?.cuscode || ""),
  },
  {
    title: "ชื่อลูกค้า",
    dataIndex: "cusname",
    key: "cusname",
    width: "20%",
    sorter: (a, b) => (a?.cusname || "").localeCompare(b?.cusname || ""),
  },
  {
    title: "ที่อยู่",
    dataIndex: "idno",
    key: "idno",
    width: "28%",
    sorter: (a, b) => (a?.idno || "").localeCompare(b?.idno || ""),
    render: (_, head) => (
      <Typography.Text>
        {head.idno && `${head.idno}`}
        {head.road && ` ${head.road}`}
        {head.subdistrict && ` ${head.subdistrict}`}
        {head.district && ` ${head.district}`}
        {head.province && ` ${head.province}`}
        {head.zipcode && ` ${head.zipcode}`}
        {head.tel && ` ${head.tel}`}
      </Typography.Text>
    ),
  },
  {
    title: "ประเทศ",
    dataIndex: "country",
    key: "country",
    sorter: (a, b) => (a?.country || "").localeCompare(b?.country || ""),
    width: 140,
  },
  {
    title: "สถานะ",
    dataIndex: "active_status",
    key: "active_status",
    width: 120,
    sorter: (a, b) =>
      (a?.active_status || "").localeCompare(b?.active_status || ""),
    render: (data) => (
      <div>
        {data === "เปิดการใช้งาน" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "created_date",
    key: "created_date",
    width: 120,
    sorter: (a, b) =>
      (a?.created_date || "").localeCompare(b?.created_date || ""),
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
    title: "Action",
    key: "operation",
    width: "60px",
    fixed: "right",
    render: (text, record) => (
      <Space>
        <Button
          icon={<EditOutlined />}
          className="bn-primary-outline"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => handleEdit(record)}
          size="small"
        />
      </Space>
    ),
  },
];

export const columnsParametersEditable = (handleSave,{handleRemove} ) =>{
  const col = ProductColumn({handleRemove});
  return col.map((col, ind) => {
      if (!col.editable) return col; 
      
      return {
          ...col,
          onCell: (record) => {
            // console.log(record);
            return {
              record,
              editable: col.editable,
              dataIndex: col.dataIndex,
              title: col.title,
              // required: !!col?.required,
              type: col?.type || "input",
              handleSave,
            }
          },
      };
  }); 
}

export const ProductColumn = ({ handleRemove }) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    align: "center",
    width: "5%",
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "PG Code",
    key: "pg_code",
    dataIndex: "pg_code", 
    width:"15%",
  },
  {
    title: "PG Name",
    dataIndex: "pg_name",
    key: "pg_name",
    width:"15%",
  },
  {
    title: "Customer PG Name",
    dataIndex: "pg_stname_cus",
    key: "pg_stname_cus",
    width:"15%",
  },
  {
    title: "PG Invoice Name",
    dataIndex: "pg_ivname",
    key: "pg_ivname",
    width:"15%",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: "10%",
    align: "right",
    className: "!pe-3",
    editable: true,
    required: true,
    type: "number",
    render: (_, rec) => <>{formatMoney(Number(rec?.price || 0), 2, 2)}</>,
  },
  {
    title: "ตัวเลือก",
    align: "center",
    key: "operation",
    dataIndex: "operation",
    render: (_, record, idx) => handleRemove(record),
    width: "4%",
    fixed: "right",
  },
];

export const customer = {
  id: null,
  cuscode: null,
  cusname: null,
  prename: null,
  idno: null,
  road: null,
  subdistrict: null,
  district: null,
  province: null,
  zipcode: null,
  country: null,
  delidno: null,
  delroad: null,
  delsubdistrict: null,
  deldistrict: null,
  delprovince: null,
  delzipcode: null,
  delcountry: null,
  tel: null,
  fax: null,
  taxnumber: null,
  email: null,
  active_status: "Y",
};
