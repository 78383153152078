import React from "react";
import { Route } from "react-router-dom";

import { Unit, UnitAccess, UnitManage } from "../pages/ERP/unit";
import { Item, ItemAccess, ItemManage } from "../pages/ERP/item";
import { PG, PGAccess, PGManage } from "../pages/ERP/pg";
import { FG, FGAccess, FGManage } from "../pages/ERP/fg";
import {
  Runcode,
  RuncodeAccess,
  RuncodeManage,
} from "../pages/ERP/runcode";
import { Brand, BrandAccess, BrandManage } from "../pages/ERP/brand";

import {
  Customer,
  CustomerAccess,
  CustomerManage,
} from "../pages/ERP/customer";
import {
  Packaging,
  PackagingAccess,
  PackagingManage,
} from "../pages/ERP/packaging";
import { Packing, PackingAccess, PackingManage } from "../pages/ERP/packing";

export const ERPDataRouter = (
  <>
    <Route path="/ERP/unit/" exact element={<Unit />}>
      <Route index element={<UnitAccess />} />
      <Route path="manage/:action" element={<UnitManage />} />
    </Route>

    <Route path="/ERP/brand/" exact element={<Brand />}>
      <Route index element={<BrandAccess />} />
      <Route path="manage/:action" element={<BrandManage />} />
    </Route>

    <Route path="/ERP/items/" exact element={<Item />}>
      <Route index element={<ItemAccess />} />
      <Route path="manage/:action" element={<ItemManage />} />
    </Route>

    <Route path="/ERP/packing-goods/" exact element={<PG />}>
      <Route index element={<PGAccess />} />
      <Route path="manage/:action" element={<PGManage />} />
    </Route>

    <Route path="/ERP/finished-goods/" exact element={<FG />}>
      <Route index element={<FGAccess />} />
      <Route path="manage/:action" element={<FGManage />} />
    </Route>

    <Route path="/ERP/packaging/" exact element={<Packaging />}>
      <Route index element={<PackagingAccess />} />
      <Route path="manage/:action" element={<PackagingManage />} />
    </Route>

    <Route path="/ERP/packing-master/" exact element={<Packing />}>
      <Route index element={<PackingAccess />} />
      <Route path="manage/:action" element={<PackingManage />} />
    </Route>

    <Route path="/ERP/runcode/" exact element={<Runcode />}>
      <Route index element={<RuncodeAccess />} />
      <Route path="manage/:action" element={<RuncodeManage />} />
    </Route>

    <Route path="/ERP/customer/" exact element={<Customer />}>
      <Route index element={<CustomerAccess />} />
      <Route path="manage/:action" element={<CustomerManage />} />
    </Route>
  </>
);
