import React from "react";
import { Route } from "react-router-dom";

import { JobOrder, JobOrderAccess, JobOrderManage } from "../pages/ERP/joborder";

export const ERPRouter = (
  <>
    <Route path="/ERP/job-order/" exact element={<JobOrder />}>
      <Route index element={<JobOrderAccess />} />
      <Route path="manage/:action" element={<JobOrderManage />} />
    </Route>
  </>
);

