import React from "react";
import { Route } from "react-router-dom";

import {
  SampleRequest,
  SampleRequestIndex,
  SampleRequestForm,
  SampleRequestView,
} from "../pages/sr/sample-request";
import {
  SamplePreparation,
  SamplePreparationIndex,
  SamplePreparationForm,
  SamplePreparationView,
} from "../pages/sp/sample-preparation";
import {
  PilotScale,
  PilotScaleAccess,
  PilotScaleManage,
  PilotScaleView,
} from "../pages/pilot-scale";
import {
  DeliveryNote,
  DeliveryNoteAccess,
  DeliveryNoteManage,
} from "../pages/delivery-note";

import {
  Estimation,
  EstimationAccess,
  EstimationManage,
} from "../pages/estimation";
import {
  Quotation,
  QuotationAccess,
  QuotationManage,
} from "../pages/quotation";

export const RDRouter = (
  <>
    <Route path="/sample-request/" exact element={<SampleRequest />}>
      <Route index element={<SampleRequestIndex />} />
      <Route path="manage/:action" element={<SampleRequestForm />} />
      <Route path="view" element={<SampleRequestView />} />
    </Route>

    <Route path="/sample-preparation/" exact element={<SamplePreparation />}>
      <Route index element={<SamplePreparationIndex />} />
      <Route path="manage/:action" element={<SamplePreparationForm />} />
      <Route path="view" element={<SamplePreparationView />} />
    </Route>

    <Route path="/pilot-scale/" exact element={<PilotScale />}>
      <Route index element={<PilotScaleAccess />} />
      <Route path="manage/:action" element={<PilotScaleManage />} />
      <Route path="view" element={<PilotScaleView />} />
    </Route>

    <Route path="/delivery-note/" exact element={<DeliveryNote />}>
      <Route index element={<DeliveryNoteAccess />} />
      <Route path="manage/:action" element={<DeliveryNoteManage />} />
    </Route>

    <Route path="/quotation/" exact element={<Quotation />}>
      <Route index element={<QuotationAccess />} />
      <Route path="manage/:action" element={<QuotationManage />} />
    </Route>

    <Route path="/estimation/" exact element={<Estimation />}>
      <Route index element={<EstimationAccess />} />
      <Route path="manage/:action" element={<EstimationManage />} />
    </Route>
  </>
);
