import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import PageNotFound from "../pages/404";
// import User from "../pages/User";
import PrivateRoute from "../components/auth/PrivateRoutes";

import { RDRouter } from "./rd.router";
import { RDDataRouter } from "./rddata.router";

import { ERPDataRouter } from "./erpdata.router";
import { ERPRouter } from "./erp.router";

import { PrintRouter } from "./print.route";
import { FileControl } from "../pages/file-control/file-control";

import { User, UserAccess, UserManage } from "../pages/user";

import { DashBoard } from "../pages/dashboard";

import { ROLES, LAYOUT } from "../constant/constant";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<Login />} />

        <Route
          element={<PrivateRoute allowdRole={[ROLES.ADMIN, ROLES.USER]} />}
        >

          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/file-control" element={<FileControl />} />

          {RDDataRouter}
          {RDRouter}    
          {ERPDataRouter}  
          {ERPRouter}
          
        </Route>

        <Route element={<PrivateRoute allowdRole={[ROLES.ADMIN]} />}>
          <Route path="/user/" exact element={<User />}>
            <Route index element={<UserAccess />} />
            <Route path="manage/:action" element={<UserManage />} />
          </Route>
        </Route>

        <Route
          element={
            <PrivateRoute
              allowdRole={[ROLES.ADMIN, ROLES.USER]}
              layout={LAYOUT.ALOND}
            />
          }
        >
          {PrintRouter}
        </Route>


        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
