/** get items column */
// import { TagItemTypes } from "../../../badge-and-tag/ERP";
export const columns = ()=>{
  return [
    {
      title: "PG Code",
      key: "pg_code",
      dataIndex: "pg_code", 
      width:"25%",
    },
    {
      title: "PG Name",
      dataIndex: "pg_name",
      key: "pg_name",
      width:"25%",
    },
    {
      title: "Customer PG Name",
      dataIndex: "pg_stname_cus",
      key: "pg_stname_cus",
      width:"25%",
    },
    {
      title: "PG Invoice Name",
      dataIndex: "pg_ivname",
      key: "pg_ivname",
      width:"25%",
    },
    
  ]
};