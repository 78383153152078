import { Badge, Space } from "antd";
import { Button } from "antd";
// import { PrinterOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
// import dayjs from 'dayjs';

export const accessColumn = ({ handleEdit, handleDelete, handleView }) => [
  {
    title: "id",
    key: "id",
    dataIndex: "id",
    align: "left",
    hidden: true,
  },
  {
    title: "Packaging Code",
    dataIndex: "pk_code",
    key: "pk_code",
    width: "40%",
    sorter: (a, b) => (a?.pk_code || "").localeCompare(b?.pk_code || ""),
  },
  {
    title: "Packaging Name",
    dataIndex: "pk_name",
    key: "pk_name",
    width: "40%",
    sorter: (a, b) => (a?.pk_name || "").localeCompare(b?.pk_name || ""),
  },
  {
    title: "สถานะ",
    dataIndex: "active_status",
    key: "active_status",
    width: "20%",
    sorter: (a, b) => (a?.active_status || "").localeCompare(b?.active_status || ""),
    render: (data) => (
      <div>
        {data === "เปิดการใช้งาน" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
  {
    title: "Action",
    key: "operation",
    width: "10%",
    fixed: "right",
    render: (text, record) => (
      <Space>
        <Button
          icon={<EditOutlined />}
          className="bn-primary-outline"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => handleEdit(record)}
          size="small"
        />
      </Space>
    ),
  },
];

export const pkmaster = {
  pk_code : null,
  pk_name : null,
  pktype_id : null,
  fill_volume: 0,
  price : 0,
  unit : null,
  remark : null,
  dimension : null,
  weight_unit : 0,
}

// export const volumelist = [
//   { value:"100", label: "100 ml." },
//   { value:"200", label: "200 ml." },
//   { value:"500", label: "500 ml." },
//   { value:"1000", label: "1,000 ml." },
// ];
