import { requestService as api, getParmeter } from "../Request.service"  
const API_URL = {
  OPTION_RUNCODE: `/ERP/common/options-runcode.php`, 
  OPTION_GETLASTCODE: `/ERP/common/options-getlastcode.php`, 
  OPTION_ITEM: `/ERP/common/options-item.php`, 
  OPTION_ITEMTYPE: `/ERP/common/options-itemtype.php`,
  OPTION_PG: `/ERP/common/options-pg.php`, 
  OPTION_FG: `/ERP/common/options-fg.php`,  
  OPTION_CUSTOMER: `/ERP/common/options-customer.php`, 
  OPTION_BRAND: `/ERP/common/options-brand.php`, 
  OPTION_UNIT: `/ERP/common/options-unit.php`,
  OPTION_PACKAGING: `/ERP/common/options-packaging.php`,
  OPTION_PACKING: `/ERP/common/options-packing.php`,
};
 

const OptionService = () => {
  const optionsRuncode = (parm = {}) => api.get(`${API_URL.OPTION_RUNCODE}?${getParmeter(parm)}`, { ignoreLoading : true });  
  const optionsGetLastcode = (parm = {}) => api.get(`${API_URL.OPTION_GETLASTCODE}?${getParmeter(parm)}`, { ignoreLoading : true });  
  const optionsItems = (parm = {}) => api.get(`${API_URL.OPTION_ITEM}?${getParmeter(parm)}`, { ignoreLoading : true });  
  const optionsItemType = () => api.get(`${API_URL.OPTION_ITEMTYPE}`, { ignoreLoading : true }); 
  const optionsPG = (parm = {}) => api.get(`${API_URL.OPTION_PG}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsFG = (parm = {}) => api.get(`${API_URL.OPTION_FG}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsCustomer = (parm = {}) => api.get(`${API_URL.OPTION_CUSTOMER}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsBrand = (parm = {}) => api.get(`${API_URL.OPTION_BRAND}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsUnit = () => api.get(`${API_URL.OPTION_UNIT}`, { ignoreLoading : true });
  const optionsPackaging = (parm = {}) => api.get(`${API_URL.OPTION_PACKAGING}?${getParmeter(parm)}`, { ignoreLoading : true });
  const optionsPacking = (parm = {}) => api.get(`${API_URL.OPTION_PACKING}?${getParmeter(parm)}`, { ignoreLoading : true });
  return {
    optionsRuncode,
    optionsGetLastcode,
    optionsItems,    
    optionsItemType,
    optionsPG,
    optionsFG,
    optionsCustomer,
    optionsBrand,
    optionsUnit,
    optionsPackaging,
    optionsPacking
  };
};

export default OptionService;