/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Divider, Form, Input, Button, Flex, message, AutoComplete, Radio } from "antd";
import { Row, Col, Space } from "antd";

import { SaveFilled } from  "@ant-design/icons";

import { brand } from '../../../../pages/ERP/brand/model.js';  
// import { delay } from '../../../utils/util';
// import OptionService from '../../service/Options.service';
import BrandService from '../../../../service/ERP/Brand.service.js'; 
import OptionService from '../../../../service/ERP/Options.service.js';
import { CreateInput } from 'thai-address-autocomplete-react';


const InputThaiAddress = CreateInput();
const brandService = BrandService();
const opservice = OptionService();
// const opservice = OptionService();

const THAICOUNTRY = "ไทย";
const ModalBrandManage = ({submit}) => { 
    const [form] = Form.useForm();
    
    const [formDetail, setFormDetail] = useState(brand);
    
    const [countriesOption, setCountriesOption] = useState([]); 
    const [countries, setCountries] = useState(null);
    const [deliveryCountries, setDeliveryCountries] = useState(null);

    // const [packageTypeOption, setPackageTypeOption] = useState([]); 
    const init = async () => {  
        const cuscodeRes = await brandService.getcode();

        const { data: cuscode } = cuscodeRes.data;
        const initForm = { ...formDetail, cuscode };
        setFormDetail((state) => ({ ...state, ...initForm }));
        form.setFieldsValue(initForm);

        const [
            countryOptionRes,
        ] = await Promise.all([
            opservice.optionsCountries(),
        ]);  
        const { data:op } = countryOptionRes.data;  
        setCountriesOption( op );
    }
 
    useEffect( ()=>{   
        init(); 
        return () => {}
    }, []); 
 
    const handleSelect = (address) => {
        const f = form.getFieldsValue();
        const addr = {
            ...f, 
            province: `จ.${address.province}`,
            zipcode: `${address.zipcode}`,
            subdistrict: `ต.${address.district}`,
            district: `อ.${address.amphoe}`,
        }
        setFormDetail(addr);
        form.setFieldsValue(addr);
    };
 
    const handleDeliverySelect = (address) => {
        const f = form.getFieldsValue();
        const addr = {
            ...f,  
            delprovince: `จ.${address.province}`,
            delzipcode: `${address.zipcode}`,
            delsubdistrict: `ต.${address.district}`,
            deldistrict: `อ.${address.amphoe}`,
        }
        setFormDetail(addr);
        form.setFieldsValue(addr);
    };

    const handleConfirm = () => {
        form.validateFields().then(  (v) => {
            const source = {...formDetail, ...v}; 
            submit( source );
        })
    } 

    const Detail = () => (
        <Row gutter={[8,8]} className='px-2 sm:px-4 md:px-4 lg:px-4'>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16} >
                <Form.Item label='Brand Name' name='brand_name' rules={[ { required: true, message: "Please enter data!", } ]}>
                    <Input placeholder='Enter Brand Name.' />
                </Form.Item> 
            </Col>     
        </Row>
    );

    const SectionBottom = (
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='start'>
                    {/* <ButtonBack target={from} /> */}
                </Flex>
            </Col>
            <Col span={12} style={{paddingInline:0}}>
                <Flex gap={4} justify='end'>
                    <Button 
                    icon={<SaveFilled style={{fontSize:'1rem'}} />} 
                    type='primary' style={{ width:'9.5rem' }} 
                    onClick={()=>{ handleConfirm() }} 
                    >Save</Button>
                </Flex>
            </Col>
        </Row>         
    );


    return (
        <div className='customer-manage xs:px-0 sm:px-0 md:px-8 lg:px-8'>
            <Space direction='vertical' className='flex gap-2' >
                {/* {SectionTop}            */}
                <br />
                <Form form={form} layout="vertical" autoComplete="off" >
                    {/* <Divider orientation="left" plain style={{margin:10}}> Brand Detail </Divider> */}
                    <Detail />

                </Form>
                {SectionBottom}           
            </Space>
        </div>
    );
}

export default ModalBrandManage;
