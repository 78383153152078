/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Flex,
  message,
  Badge,
  Card,
  Select,
  InputNumber,
  Divider,
  Typography,
} from "antd";
import { Row, Col, Space } from "antd";
// import { Upload,Modal } from "antd";
import { SaveFilled, SearchOutlined } from "@ant-design/icons";
import { ButtonBack } from "../../../components/button/index.js";
// import { uploadButton } from "../../../components/button/index.js";
import { PG } from "./model.js";
import { useLocation, useNavigate } from "react-router";
import { delay } from "../../../utils/util.js";
// import { BACKEND_URL_MAIN } from "../../../utils/util.js";
// import Swal from "sweetalert2";
import PGservice from "../../../service/ERP/PG.service.js";
import OptionService from "../../../service/ERP/Options.service.js";
import { Authenticate } from "../../../service/Authenticate.service.js";

import ModalBrand from "../../../components/modal/ERP/brand/ModalBrand";
import ModalFG from "../../../components/modal/ERP/fg/ModalFG";
import ModalPacking from "../../../components/modal/ERP/packing/ModalPacking.jsx";

const pgservice = PGservice();
const opService = OptionService();
const authService = Authenticate();
const { Text } = Typography;

const from = "/ERP/packing-goods";

const ItemManage = () => {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const location = useLocation();

  const { config } = location.state || { config: null };
  const [form] = Form.useForm();
  // const [formImage] = Form.useForm();
  const [formDetail, setFormDetail] = useState(PG);
  const [optionUnit, setOptionUnit] = useState([]);
  const [optionPrefix, setOptionPrefix] = useState([]);
  const [openModalBrand, setOpenModalBrand] = useState(false);
  const [openModalFG, setOpenModalFG] = useState(false);
  const [openModalPacking, setOpenModalPacking] = useState(false);
    const [prefixCode, setPrefixCode] = useState(null);

  useEffect(() => {
    // setLoading(true);
    GetItemsUnit();
    GetPrefix();
    if (config?.action !== "create") {
      getsupData(config.code);
      
    } else {
      // form.setFieldValue("weight_nw", 0);
      // form.setFieldValue("weight_gw", 0);
    }
    // console.log(config);

    return () => {
      form.resetFields();
    };
  }, []);
  
    const GetPrefix = () => {
      opService.optionsRuncode({ p: "pg" }).then((res) => {
        let { data } = res.data;
        // let code = data.run_number
        setOptionPrefix(data);
      });
    };

  const handleChoosedFG = (value) => {
    // console.log(value);
    const f = form.getFieldValue();
    const initialValues = {
      ...formDetail,
      ...f,
      fg_code: value.fg_code,
      fg_name: value.fg_name,
    };
    form.setFieldsValue(initialValues);
    setFormDetail(initialValues);
  };

  const handleChoosedPacking = (value) => {
    // console.log(value);
    const f = form.getFieldValue();
    const initialValues = {
      ...formDetail,
      ...f,
      packing_code: value.packing_code,
      packing_name: value.packing_name,
    };
    form.setFieldsValue(initialValues);
    setFormDetail(initialValues);
  };

  const handleChoosedBrand = (value) => {
    // console.log(value);
    const f = form.getFieldValue();
    const initialValues = {
      ...formDetail,
      ...f,
      brand_code: value.brand_code,
      brand_name: value.brand_name,
    };
    form.setFieldsValue(initialValues);
    setFormDetail(initialValues);
  };

  const GetItemsUnit = () => {
    opService.optionsUnit().then((res) => {
      let { data } = res.data;
      setOptionUnit(data);
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getsupData = (v) => {
    pgservice
      .get(v)
      .then(async (res) => {
        const { data } = res.data;
        // const { data, file } = res.data;

        const init = {
          ...data,
        };

        setFormDetail(init);
        form.setFieldsValue({ ...init });
        form.setFieldValue("pg_code1", data.pg_code);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error getting infomation PG.");
      });
  };

  const handleConfirm = () => {
    form.validateFields().then(() => {
      const source = { ...formDetail, ...form.getFieldsValue() };
      const actions =
        config?.action !== "create"
          ? pgservice.update(source)
          : pgservice.create(source);

      actions
        .then(async (r) => {
          message.success("Request success.");
          navigate(from, { replace: true });
          await delay(300);
        })
        .catch((err) => {
          console.warn(err);
          const data = err?.response?.data;
          message.error(data?.message || "บันทึกไม่สำเร็จ");
        });
    });
  };

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const prefixSelector = (
      <Form.Item name="prefixid" noStyle>
        <Select
          style={{
            width: 100,
          }}
          onChange={(v,l)=>          
            opService.optionsGetLastcode({p:'packaging',id:v}).then((res) => {
              let { data } = res;            
              setPrefixCode(data.data)
              const val = {
                ...formDetail,
                prefix: l.label,
              };
              setFormDetail(val)
              
            })}
          options={optionPrefix.map((item) => ({
            value: item.id,
            label: item.value,
          }))}
        ></Select>
      </Form.Item>
    );

  let redcode = <span style={{ color: "red" ,paddingLeft:"15px"}}>{prefixCode!==null?" รหัสล่าสุด "+prefixCode:""} </span>

  const Detail = (
    <>
      <Row gutter={[8, 8]} className="px-2 sm:px-4 md:px-4 lg:px-4">
        <Divider {...dividerProp}>Basic Data</Divider>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={
            config.action !== "edit"
              ? { display: "inline" }
              : { display: "none" }
          }>
                  <Form.Item
                    label={
                      <span>
                        <span >PG Code</span>
                        {redcode}
                      </span>
                    }
                    name="pg_code"
                    rules={[{ required: true, message: "Please enter data!" }]}
                  >
                    <Input
                      addonBefore={
                        <Text
                          style={{
                            width: 150,
                          }}
                        >
                          {prefixSelector}
                        </Text>
                      }
                      placeholder="Enter PG Code."
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  style={
                    config.action === "edit"
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                >
                  <Form.Item label="PG Code" name="pg_code1">
                    <Input readOnly />
                  </Form.Item>
                </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item
            label="PG Thai Name"
            name="pg_name"
            rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
          >
            <Input placeholder="Enter PG Thai Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item name="pg_stname_cus" label="Customer PG Name">
            <Input placeholder="Enter Customer PG Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item
            label="PG Invoice Name"
            name="pg_ivname"
          >
            <Input placeholder="Enter PG English Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={6}>
          <Form.Item
            label="Finished Goods :"
            name="fg_code"
            // rules={[{ required: true, message: "Please choose data!" }]}
          >
            <Space.Compact style={{ width: "100%" }}>
              <Input
                readOnly
                placeholder="Select Finished Goods"
                value={!!formDetail.fg_name ? `${formDetail.fg_name} ` : ""}
              />
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => setOpenModalFG(true)}
                style={{ minWidth: 40 }}
              ></Button>
            </Space.Compact>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={6}>
          <Form.Item
            label="Packing :"
            name="packing_code"
            // rules={[{ required: true, message: "Please choose data!" }]}
          >
            <Space.Compact style={{ width: "100%" }}>
              <Input
                readOnly
                placeholder="Select Packing"
                value={
                  !!formDetail.packing_name ? `${formDetail.packing_name} ` : ""
                }
              />
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => setOpenModalPacking(true)}
                style={{ minWidth: 40 }}
              ></Button>
            </Space.Compact>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={4}>
          <Form.Item label="Unit" name="unit">
            <Select
              size="large"
              showSearch
              filterOption={filterOption}
              placeholder="Select Unit"
              options={optionUnit.map((item) => ({
                value: item.value,
                label: item.value,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={6}>
          <Form.Item
            label="Brand :"
            name="brand_code"
            // rules={[{ required: true, message: "Please choose data!" }]}
          >
            <Space.Compact style={{ width: "100%" }}>
              <Input
                readOnly
                placeholder="Select Brand"
                value={
                  !!formDetail.brand_name ? `${formDetail.brand_name} ` : ""
                }
              />
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => setOpenModalBrand(true)}
                style={{ minWidth: 40 }}
              ></Button>
            </Space.Compact>
          </Form.Item>
        </Col>
      </Row>
      <Divider {...dividerProp}>Detail Data</Divider>
      <Row gutter={[8, 8]} className="px-2 sm:px-4 md:px-4 lg:px-4">
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item name="unit_cs" label="Unit/cs">
            <InputNumber
              style={{ width: "100%" }}
              size="large"
              min="0"
              step="0.01"
              placeholder="Enter Unit/cs"
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={4}
          style={
            config.action === "edit"
              ? { display: "inline" }
              : { display: "none" }
          }
        >
          <Form.Item label="สถานะการใช้งาน" name="active_status">
            <Select
              size="large"
              options={[
                {
                  value: "เปิดการใช้งาน",
                  label: <Badge status="success" text="เปิดการใช้งาน" />,
                },
                {
                  value: "ปิดการใช้งาน",
                  label: <Badge status="error" text="ปิดการใช้งาน" />,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[8, 8]} className="px-2 sm:px-4 md:px-4 lg:px-4">
        <Divider {...dividerProp}>Other Data</Divider>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item name="remarks" label="Remark">
            <TextArea rows={4} placeholder="Enter Remark " />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const SectionBottom = (
    <Row
      gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
      className="m-0"
    >
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start">
          <ButtonBack target={from} />
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">

          <Button
            icon={<SaveFilled style={{ fontSize: "1rem" }} />}
            type="primary"
            style={{ width: "9.5rem" }}
            onClick={() => {
              handleConfirm();
            }}
          >
            บันทึก
          </Button>
        </Flex>
      </Col>
    </Row>
  );
  return (
    <div className="pg-manage xs:px-0 sm:px-0 md:px-8 lg:px-8">
      {/* <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal> */}
      <Space direction="vertical" className="flex gap-2">
        <Form
          form={form}
          name="form_in_modal_add"
          layout="vertical"
          autoComplete="off"
          initialValues={{
            modifier: "public",
          }}
        >
          <Card title={config?.title}>{Detail}</Card>
        </Form>
        {SectionBottom}
      </Space>

      {openModalBrand && (
        <ModalBrand
          show={openModalBrand}
          close={() => {
            setOpenModalBrand(false);
          }}
          values={(v) => {
            handleChoosedBrand(v);
          }}
        ></ModalBrand>
      )}

      {openModalFG && (
        <ModalFG
          show={openModalFG}
          close={() => {
            setOpenModalFG(false);
          }}
          values={(v) => {
            handleChoosedFG(v);
          }}
        ></ModalFG>
      )}
      
      {openModalPacking && (
        <ModalPacking
          show={openModalPacking}
          close={() => {
            setOpenModalPacking(false);
          }}
          values={(v) => {
            handleChoosedPacking(v);
          }}
        ></ModalPacking>
      )}
    </div>
  );
};

export default ItemManage;
