import { Tooltip, Space,Image } from "antd";
import { Button } from "antd";
// import { PrinterOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { 
  BACKEND_URL_MAIN
 } from '../../../utils/util';
// import dayjs from 'dayjs';

export const accessColumn = ({ handleEdit, handleDelete, handleView }) => [
  {
    title: "รูปประกอบ",
    dataIndex: "file",
    key: "file",
    width: 120,
    // align: "center",
    render: (im, rec) => 
      {
        const img = (!!rec.file_name ? `/uploads/ERP/item/` + rec.file_name : `/logo.png`
        );
        return <>
        <Image
      style={{ borderRadius: 10 }}
      preview={false}
      height={30}
      alt={`Image ${rec.file_name}`}
      src={`${BACKEND_URL_MAIN}` + img}
    />
    </>
    },
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    sorter: (a, b) => (a?.stcode || "").localeCompare(b?.stcode || ""),
    width: 140,
  },
  {
    title: "Express Code",
    dataIndex: "express_code",
    key: "express_code",
    sorter: (a, b) => (a?.express_code || "").localeCompare(b?.express_code || ""),
    width: 140,
  },
  {
    title: "ชื่อสินค้า(TH)",
    dataIndex: "stname",
    key: "stname",
    width: "25%",
    sorter: (a, b) => (a?.stname || "").localeCompare(b?.stname || ""),
    ellipsis: { showTitle: false, },
    render: (v) => <Tooltip placement="topLeft" title={v}>{v}</Tooltip>,
  },
  {
    title: "ชื่อสินค้า(EN)",
    dataIndex: "stnameEN",
    key: "stnameEN",
    width: "25%",
    sorter: (a, b) => (a?.stnameEN || "").localeCompare(b?.stnameEN || ""),
    ellipsis: { showTitle: false, },
    render: (v) => <Tooltip placement="topLeft" title={v}>{v}</Tooltip>,
  },
  // {
  //   title: "ราคา",
  //   dataIndex: "price",
  //   key: "price",
  //   className: "!pe-4",
  //   align:'right',
  //   sorter: (a, b) => (a?.price || "").localeCompare(b?.price || ""),
  //   render:(v)=>formatMoney( Number(v || 0))
  // },
  {
    title: "Action",
    key: "operation",
    width: "60px",
    fixed: "right",
    render: (text, record) => (
      <Space>
        <Button
          icon={<EditOutlined />}
          className="bn-primary-outline"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => handleEdit(record)}
          size="small"
        />
      </Space>
    ),
  },
];

export const Item = {
  id: null,
  stcode: null,
  stname: null,
  supcode: null,
  prename: null,
  idno: null,
  road: null,
  subdistrict: null,
  district: null,
  province: null,
  zipcode: null,
  country: null,
  delidno: null,
  delroad: null,
  delsubdistrict: null,
  deldistrict: null,
  delprovince: null,
  delzipcode: null,
  delcountry: null,
  tel: null,
  fax: null,
  taxnumber: null,
  email: null,
  active_status: "Y",
};
