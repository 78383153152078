/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Flex,
  message,
  Badge,
  Card,
  Select,
  Typography,
  Divider,
} from "antd";
import { Row, Col, Space } from "antd";
// import { Upload,Modal } from "antd";
import { SaveFilled, SearchOutlined } from "@ant-design/icons";
import { ButtonBack } from "../../../components/button/index.js";
// import { uploadButton } from "../../../components/button/index.js";
import { Product } from "./model.js";
import { useLocation, useNavigate } from "react-router";
import { delay } from "../../../utils/util.js";
// import { BACKEND_URL_MAIN } from "../../../utils/util.js";
// import Swal from "sweetalert2";
import FGservice from "../../../service/ERP/FG.service.js";
import OptionService from "../../../service/ERP/Options.service.js";
import { Authenticate } from "../../../service/Authenticate.service.js";

import ModalPK from "../../../components/modal/ERP/pk/ModalPK";
import ModalLid from "../../../components/modal/ERP/pk/ModalPK";

const fgservice = FGservice();
const opService = OptionService();
const authService = Authenticate();
const { Text } = Typography;

const from = "/ERP/finished-goods";

const ItemManage = () => {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const location = useLocation();

  const { config } = location.state || { config: null };
  const [form] = Form.useForm();
  // const [formImage] = Form.useForm();
  const [formDetail, setFormDetail] = useState(Product);
  const [optionUnit, setOptionUnit] = useState([]);
  const [optionPrefix, setOptionPrefix] = useState([]);
  const [openModalPK, setOpenModalPK] = useState(false);
  const [openModalLid, setOpenModalLid] = useState(false);
  const [prefixCode, setPrefixCode] = useState(null);

  useEffect(() => {
    // setLoading(true);
    GetItemsUnit();
    GetPrefix();
    if (config?.action !== "create") {
      getsupData(config.code);
    } else {
      form.setFieldValue("weight_nw", 0);
      form.setFieldValue("weight_gw", 0);
    }
    // console.log(config);

    return () => {
      form.resetFields();
    };
  }, []);

  const GetPrefix = () => {
    opService.optionsRuncode({ p: "fg" }).then((res) => {
      let { data } = res.data;
      // let code = data.run_number
      setOptionPrefix(data);
    });
  };

  const handleChoosedPK = (value) => {
    // console.log(value);
    const f = form.getFieldValue();
    const initialValues = {
      ...formDetail,
      ...f,
      pk_code: value.pk_code,
      pk_name: value.pk_name,
    };
    form.setFieldsValue(initialValues);
    setFormDetail(initialValues);
  };

  const handleChoosedLid = (value) => {
    // console.log(value);
    const f = form.getFieldValue();
    const initialValues = {
      ...formDetail,
      ...f,
      lid_code: value.pk_code,
      lid_name: value.pk_name,
    };
    form.setFieldsValue(initialValues);
    setFormDetail(initialValues);
  };

  const GetItemsUnit = () => {
    opService.optionsUnit().then((res) => {
      let { data } = res.data;
      setOptionUnit(data);
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getsupData = (v) => {
    fgservice
      .get(v)
      .then(async (res) => {
        const { data } = res.data;
        // const { data, file } = res.data;

        const init = {
          ...data,
        };

        setFormDetail(init);
        form.setFieldsValue({ ...init });
        form.setFieldValue("fg_code1", data.fg_code);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error getting infomation FG.");
      });
  };

  const handleConfirm = () => {
    form.validateFields().then(() => {
      const source = {
        ...formDetail,
        ...form.getFieldsValue(),
        id: config.code,
      };
      const actions =
        config?.action !== "create"
          ? fgservice.update(source)
          : fgservice.create(source);

      actions
        .then(async (r) => {
          message.success("Request success.");
          navigate(from, { replace: true });
          await delay(300);
        })
        .catch((err) => {
          console.warn(err);
          const data = err?.response?.data;
          message.error(data?.message || "บันทึกไม่สำเร็จ");
        });
    });
  };

  const dividerProp = {
    orientation: "left",
    style: { marginBlock: 10 },
    className: "!border-black",
  };

  const prefixSelector = (
      <Form.Item name="prefixid" noStyle>
        <Select
          style={{
            width: 100,
          }}
          onChange={(v,l)=>          
            opService.optionsGetLastcode({p:'packaging',id:v}).then((res) => {
              let { data } = res;            
              setPrefixCode(data.data)
              const val = {
                ...formDetail,
                prefix: l.label,
              };
              setFormDetail(val)
              
            })}
          options={optionPrefix.map((item) => ({
            value: item.id,
            label: item.value,
          }))}
        ></Select>
      </Form.Item>
    );

  let redcode = <span style={{ color: "red" ,paddingLeft:"15px"}}>{prefixCode!==null?" รหัสล่าสุด "+prefixCode:""} </span>

  const Detail = (
    <>
      <Row gutter={[8, 8]} className="px-2 sm:px-4 md:px-4 lg:px-4">
        <Divider {...dividerProp}>Basic Data</Divider>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={
            config.action !== "edit"
              ? { display: "inline" }
              : { display: "none" }
          }>
                  <Form.Item
                    label={
                      <span>
                        <span >FG Code</span>
                        {redcode}
                      </span>
                    }
                    name="fg_code"
                    rules={[{ required: true, message: "Please enter data!" }]}
                  >
                    <Input
                      addonBefore={
                        <Text
                          style={{
                            width: 150,
                          }}
                        >
                          {prefixSelector}
                        </Text>
                      }
                      placeholder="Enter FG Code."
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  xxl={12}
                  style={
                    config.action === "edit"
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                >
                  <Form.Item label="FG Code" name="fg_code1">
                    <Input readOnly />
                  </Form.Item>
                </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item
            label="FG Thai Name"
            name="fg_name"
            rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
          >
            <Input placeholder="Enter FG Thai Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
          <Form.Item
            label="FG English Name"
            name="fg_nameEN"
          >
            <Input placeholder="Enter FG English Name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
          <Form.Item name="code" label="Code">
            <Input placeholder="Enter Code" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={4}>
          <Form.Item label="Unit" name="unit">
            <Select
              size="large"
              showSearch
              filterOption={filterOption}
              placeholder="Select Unit"
              options={optionUnit.map((item) => ({
                value: item.value,
                label: item.value,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="บรรจุภัณฑ์ :"
            name="pk_code"
          >
            <Space.Compact style={{ width: "100%" }}>
              <Input
                readOnly
                placeholder="เลือก บรรจุภัณฑ์"
                value={!!formDetail.pk_name ? `${formDetail.pk_name} ` : ""}
              />
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => setOpenModalPK(true)}
                style={{ minWidth: 40 }}
              ></Button>
            </Space.Compact>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            label="ฝาปิด :"
            name="lid_code"
          >
            <Space.Compact style={{ width: "100%" }}>
              <Input
                readOnly
                placeholder="เลือก ฝาปิด"
                value={!!formDetail.lid_name ? `${formDetail.lid_name} ` : ""}
              />
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={() => setOpenModalLid(true)}
                style={{ minWidth: 40 }}
              ></Button>
            </Space.Compact>
          </Form.Item>
        </Col>
      </Row>
      <Divider {...dividerProp}>Other Data</Divider>
      <Row gutter={[8, 8]} className="px-2 sm:px-4 md:px-4 lg:px-4">
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={4}
          style={
            config.action === "edit"
              ? { display: "inline" }
              : { display: "none" }
          }
        >
          <Form.Item label="สถานะการใช้งาน" name="active_status">
            <Select
              size="large"
              options={[
                {
                  value: "เปิดการใช้งาน",
                  label: <Badge status="success" text="เปิดการใช้งาน" />,
                },
                {
                  value: "ปิดการใช้งาน",
                  label: <Badge status="error" text="ปิดการใช้งาน" />,
                },
              ]}
            />
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item name="feature" label="Feature">
            <TextArea rows={4} placeholder="Enter Feature" />
          </Form.Item>
        </Col> */}
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Form.Item name="remarks" label="Remark">
            <TextArea rows={4} placeholder="Enter Remark " />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const SectionBottom = (
    <Row
      gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
      className="m-0"
    >
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start">
          <ButtonBack target={from} />
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">

          <Button
            icon={<SaveFilled style={{ fontSize: "1rem" }} />}
            type="primary"
            style={{ width: "9.5rem" }}
            onClick={() => {
              handleConfirm();
            }}
          >
            บันทึก
          </Button>
        </Flex>
      </Col>
    </Row>
  );
  return (
    <div className="fg-manage xs:px-0 sm:px-0 md:px-8 lg:px-8">
      {/* <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal> */}
      <Space direction="vertical" className="flex gap-2">
        <Form
          form={form}
          name="form_in_modal_add"
          layout="vertical"
          autoComplete="off"
          initialValues={{
            modifier: "public",
          }}
        >
          <Card title={config?.title}>{Detail}</Card>
        </Form>
        {SectionBottom}
      </Space>

      {openModalPK && (
        <ModalPK
          show={openModalPK}
          close={() => {
            setOpenModalPK(false);
          }}
          values={(v) => {
            handleChoosedPK(v);
          }}
        ></ModalPK>
      )}

    {openModalLid && (
        <ModalLid
          show={openModalLid}
          close={() => {
            setOpenModalLid(false);
          }}
          values={(v) => {
            handleChoosedLid(v);
          }}
        ></ModalLid>
      )}
    </div>
  );
};

export default ItemManage;
