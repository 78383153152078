/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Flex,
  message,
  Card,
  Table,
  DatePicker,
  Typography,
} from "antd";
import { Row, Col, Space } from "antd";
// import { Upload,Modal } from "antd";
import {
  SearchOutlined,
  SaveFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { ButtonBack } from "../../../components/button/index.js";
import {
  Product,
  componentsEditable,
  columnsDetailsEditable,
} from "./model.js";
import { useLocation, useNavigate } from "react-router";
import { delay, formatMoney } from "../../../utils/util.js";
import dayjs from "dayjs";

import { RiDeleteBin5Line } from "react-icons/ri";

import JobOrderservice from "../../../service/ERP/JobOrder.service.js";
import OptionService from "../../../service/ERP/Options.service.js";
import { Authenticate } from "../../../service/Authenticate.service.js";

import ModalCustomers from "../../../components/modal/ERP/customers/ModalCustomers";
import ModalProduct from "../../../components/modal/ERP/pg-single/ModalPG";

const joservice = JobOrderservice();
const opService = OptionService();
const authService = Authenticate();

const from = "/ERP/job-order";
const dateFormat = "DD/MM/YYYY";

const JobOrderManage = () => {
  const { Text } = Typography;
  const navigate = useNavigate();
  const location = useLocation();

  const { config } = location.state || { config: null };
  const [form] = Form.useForm();

  /** Job Order state */
  const [jobCode, setJobCode] = useState(null);

  // const [formImage] = Form.useForm();
  const [formDetail, setFormDetail] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [openModalCustomer, setOpenModalCustomer] = useState(false);
  const [openModalProduct, setOpenModalProduct] = useState(false);
  const [ProductValue, setProductValue] = useState({});

  useEffect(() => {
    const initeial = async () => {
      if (config.action !== "create") {
        const res = await joservice
          .get(config?.code)
          .catch((error) => message.error("get Job Order data fail."));
        const {
          data: { header, detail },
        } = res.data;
        const { jocode, jodate, etl,etd,eta } = header;
        setFormDetail(header);
        setDetailData(detail);
        setJobCode(jocode);
        // alert(dayjs(jodate))
        form.setFieldsValue({
          ...header,
          jodate: dayjs(jodate),
          etl: dayjs(etl),
          etd: dayjs(etd),
          eta: dayjs(eta),          
        });
      } else {
        const { data: code } = (
          await joservice.code().catch((e) => {
            message.error("get Job Order code fail.");
          })
        ).data;
        setJobCode(code);
        // form.setFieldValue("jodate", dayjs(new Date()));

        const ininteial_value = {
          ...formDetail,
          jocode: code,
          jodate: dayjs(new Date()),
          // doc_status: "รอออกใบส่งของ",
        };
        setFormDetail(ininteial_value);
        form.setFieldsValue(ininteial_value);
      }
    };

    initeial();
  }, [form, config]);

  const handleChoosedCustomer = (value) => {
    const f = form.getFieldValue();
    const initialValues = {
      ...f,
      cuscode: value.cuscode,
      cusname: value.cusname,
    };
    form.setFieldsValue(initialValues);
    setFormDetail(initialValues);
  };

  const handleChooseItems = (value) => {
    const newData = {
      ...ProductValue,
      pg_code: value.pg_code,
      pg_name: value.pg_name,
      code: value.code,
      pg_stname_cus: value.pg_stname_cus,
      fg_name: value.fg_name,
      unit_cs: value.unit_cs,
      carton: 1,
    };

    // console.log(newData, value);
    handleSave(newData);
  };

  const handleConfirm = () => {
    form
      .validateFields()
      .then(() => {
        if (detailData.length < 1) throw new Error("กรุณาเพิ่ม รายการขาย");

        const header = {
          ...formDetail,
          jodate: dayjs(form.getFieldValue("jodate")).format("YYYY-MM-DD"),
          pi_no: form.getFieldValue("pi_no"),
          etl: dayjs(form.getFieldValue("etl")).format("YYYY-MM-DD"),
          etd: dayjs(form.getFieldValue("etd")).format("YYYY-MM-DD"),
          eta: dayjs(form.getFieldValue("eta")).format("YYYY-MM-DD"),
          remark: form.getFieldValue("remark"),
        };
        const detail = detailData;

        const parm = { header, detail };

        const actions =
          config?.action !== "create"
            ? joservice.update(parm)
            : joservice.create(parm);

        actions
          .then(async (r) => {
            message.success("Request success.");
            navigate(from, { replace: true });
            await delay(300);
          })
          .catch((err) => {
            console.warn(err);
            const data = err?.response?.data;
            message.error(data?.message || "บันทึกไม่สำเร็จ");
          });
      })
      .catch((err) => {
        message.error("กรุณาเลือกลูกค้าก่อน");
      });
  };

  const handleSave = (row) => {
    const newData = (r) => {
      const newData = [...detailData];

      const ind = newData.findIndex((item) => row.seq === item?.seq);
      const item = newData[ind];

      newData.splice(ind, 1, {
        ...item,
        ...row,
      });
      return newData;
    };
    setDetailData([...newData()]);
  };

  // const handleSave = (row) => {
  //   // const { key } = row;
  //   const newData = (r) => {
  //     const itemDetail = [...detailData];
  //     const newData = [...itemDetail];

  //     const ind = newData.findIndex(
  //       (item) => row?.pc_code === item?.pc_code
  //     );
  //     const item = newData[ind < 0 ? 0 : ind];
  //     newData.splice(ind < 0 ? 0 : ind, 1, {
  //       ...item,
  //       ...row,
  //     });
  //     return newData;
  //   };
  //   setDetailData([...newData(row)]);
  // };

  const handleDelete = (seq) => {
    const itemDetail = [...detailData];
    const newData = itemDetail
      .filter((val) => val?.seq !== seq)
      .map((m, i) => ({ ...m, seq: i + 1 }));
    setDetailData([...newData]);
    //setItemsData([...newData]);
  };

  const handleAction = (record) => {
    const itemDetail = [...detailData];
    return itemDetail.length >= 1 ? (
      <Button
        className="bt-icon"
        size="small"
        danger
        icon={
          <RiDeleteBin5Line style={{ fontSize: "1rem", marginTop: "3px" }} />
        }
        onClick={() => handleDelete(record?.seq)}
        disabled={!record?.seq}
      />
    ) : null;
  };

  const handleAddJoDetail = () => {
    setDetailData([
      ...detailData,
      {
        seq: detailData.length + 1,
        pg_code: null,
        pg_name: null,
        unit_cs: 1,
        carton: 1,
      },
    ]);
  };

  const handleModalSelect = (e, row) => {
    // console.log(row)
    form
      .validateFields()
      .then(() => {
        setOpenModalProduct(true);
        setProductValue(row);
      })
      .catch((err) => {
        message.error("กรุณาเลือกลูกค้าก่อน");
      });
  };

  const column = columnsDetailsEditable(handleSave, handleModalSelect, {
    handleAction,
  });

  const SectionTop = (
    <Row
      gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
      className="m-0"
    >
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start">
          <ButtonBack target={from} />
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            icon={<SaveFilled style={{ fontSize: "1rem" }} />}
            type="primary"
            style={{ width: "9.5rem" }}
            onClick={() => {
              handleConfirm();
            }}
          >
            Save
          </Button>
        </Flex>
      </Col>
    </Row>
  );

  const SectionBottom = (
    <Row
      gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
      className="m-0"
    >
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start">
          <ButtonBack target={from} />
        </Flex>
      </Col>
      <Col span={12} style={{ paddingInline: 0 }}>
        <Flex gap={4} justify="end">
          <Button
            icon={<SaveFilled style={{ fontSize: "1rem" }} />}
            type="primary"
            style={{ width: "9.5rem" }}
            onClick={() => {
              handleConfirm();
            }}
          >
            บันทึก
          </Button>
        </Flex>
      </Col>
    </Row>
  );

  const TitleTable = (
    <Flex className="width-100" align="center">
      <Col span={12} className="p-0">
        <Flex gap={4} justify="start" align="center">
          <Typography.Title className="m-0 !text-zinc-800" level={3}>
            รายการสินค้า Job Order
          </Typography.Title>
        </Flex>
      </Col>
    </Flex>
  );

  return (
    <div className="product-manage xs:px-0 sm:px-0 md:px-8 lg:px-8">
      <Space
        direction="vertical"
        size="middle"
        style={{ display: "flex", position: "relative" }}
      >
        <Form form={form} layout="vertical" autoComplete="off">
          <Row
            gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
            className="m-0"
          >
            {/* <Col span={12} style={{paddingInline:0}}>{ ButtonActionLeft }</Col> */}
            <Col span={24} style={{ paddingInline: 0 }}>
              {" "}
              {SectionTop}{" "}
            </Col>
          </Row>
          <Card style={{ backgroundColor: "#f0f0f0" }}>
            <Row
              gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
              className="m-0 items-center"
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Typography.Title level={3} className="m-0">
                  JOB ORDER NO : {jobCode}
                </Typography.Title>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Flex
                  gap={10}
                  align="center"
                  className="justify-start sm:justify-end"
                >
                  <Typography.Title level={3} className="m-0">
                    JOB ORDER DATE :{" "}
                  </Typography.Title>
                  <Form.Item name="jodate" className="!m-0">
                    <DatePicker
                      className="input-40"
                      allowClear={false}
                      format={dateFormat}
                    />
                  </Form.Item>
                </Flex>
              </Col>
            </Row>
          </Card>
          <Card style={{ backgroundColor: "#f0f0f0" }}>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex", position: "relative" }}
            >
              <Row
                gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
                className="m-0"
              >
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item label="Product :" rules={[ { required: true, message: "Please choose data!", } ]} >
                        <div onClick={(v) => handleChoosOption(v)}>
                          <Radio.Group options={options} value={smValue} optionType="button" buttonStyle="solid" /> 
                        </div>
                      </Form.Item>                
                    </Col> */}
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Customer :"
                    name="cuscode"
                    rules={[{ required: true, message: "Please choose data!" }]}
                  >
                    <Space.Compact style={{ width: "100%" }}>
                      <Input
                        readOnly
                        placeholder="Select Customer"
                        value={
                          !!formDetail.cuscode
                            ? `${formDetail.cuscode} - ${formDetail.cusname}`
                            : ""
                        }
                      />
                      <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={() => setOpenModalCustomer(true)}
                        style={{ minWidth: 40 }}
                      ></Button>
                    </Space.Compact>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="PI NO :" name="pi_no">
                    <Input placeholder="Enter Order NO" />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
                className="m-0"
              >
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <Form.Item label="ETL :" name="etl">
                    <DatePicker
                      size="large"
                      className="input-40"
                      style={{ width: "100%" }}
                      format={dateFormat}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <Form.Item label="ETD :" name="etd">
                    <DatePicker
                      size="large"
                      className="input-40"
                      style={{ width: "100%" }}
                      format={dateFormat}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <Form.Item label="ETA :" name="eta">
                    <DatePicker
                      size="large"
                      className="input-40"
                      style={{ width: "100%" }}
                      format={dateFormat}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item label="Remark :" name="remark">
                    <Input.TextArea placeholder="Remark" rows={5} />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={[{ xs: 32, sm: 32, md: 32, lg: 12, xl: 12 }, 8]}
                className="m-0"
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Table
                    title={() => TitleTable}
                    components={componentsEditable}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={detailData || []}
                    columns={column}
                    pagination={false}
                    rowKey="seq"
                    scroll={{ x: "100%" }}
                    size="small"
                    locale={{
                      emptyText: (
                        <span>No data available, please add some data.</span>
                      ),
                    }}
                    summary={(v) => (
                      <>
                        {detailData.length > 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={5} align="center">  
                              รวม
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className="text-summary text-end border-right-0 pe-summary"
                            >
                              <Text type="danger" >
                                {formatMoney(
                                  v.reduce(
                                    (a, v) => (a += Number(v?.carton || 0)),
                                    0
                                  ) || 0
                                )}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={2}
                              className="text-summary text-end border-right-0 pe-summary"
                            >
                              <Text type="danger" className="underline">
                                {formatMoney(
                                  v.reduce(
                                    (a, v) =>
                                      (a +=
                                        Number(v?.carton || 0) *
                                        Number(v?.unit_cs || 0)),
                                    0
                                  ) || 0
                                )}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={2}
                              className="text-summary text-end border-right-0 pe-summary"
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1} colSpan={10}>
                            <Row>
                              <Col
                                span={24}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                className="width-100 append-step"
                              >
                                <Button
                                  shape="circle"
                                  icon={<PlusCircleOutlined />}
                                  style={{
                                    width: "2.5rem",
                                    boxShadow: "none",
                                    lineHeight: "2.5rem",
                                  }}
                                  onClick={() => {
                                    handleAddJoDetail();
                                  }}
                                ></Button>
                              </Col>
                            </Row>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ paddingInline: 0 }}>
                  {SectionBottom}
                </Col>
              </Row>
            </Space>
          </Card>
        </Form>
      </Space>

      {openModalCustomer && (
        <ModalCustomers
          show={openModalCustomer}
          close={() => {
            setOpenModalCustomer(false);
          }}
          values={(v) => {
            handleChoosedCustomer(v);
          }}
        ></ModalCustomers>
      )}

      {openModalProduct && (
        <ModalProduct
          show={openModalProduct}
          close={() => {
            setOpenModalProduct(false);
          }}
          values={(v) => {
            handleChooseItems(v);
          }}
          selected={detailData}
          cuscode={form.getFieldValue("cuscode")}
        ></ModalProduct>
      )}
    </div>
  );
};

export default JobOrderManage;
